import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { SendPolicySettings } from "src/types/SendPolicySettings";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import statesData from "src/data/regionsUSA.json";

const SendPolicy = () => {
  const { campaignId, projectId } = useUploadListContext();
  const [warningMessage, setWarningMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [canPerformAction, setCanPerformAction] = useState(false);
  const [states] = useState(statesData);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SendPolicySettings>({
    defaultValues: {
      campaignId: campaignId ?? "",
      projId: projectId ?? "",
      sentMethod: 0,
    },
  });

  const onSubmit = async (data: SendPolicySettings) => {
    try {
      if (data.campaignId === "" || data.projId === "") {
        data.campaignId = campaignId;
        data.projId = projectId;
      }
      setIsLoading(true);
      setWarningMessage("");
      const apiUrl = `main/SendPolicy/Save?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.POST, data);
      console.log("API Response:", response);
      //redirect to Internal DNC now
      if (data.addedToDNC === true) {
        const newPdncSearchDTO = {
          firstName: data.name,
          lastName: "",
          address: data.address1,
          careOf: "",
          city: data.city,
          state: data.state,
          zip: data.postCode,
          fullPhone: data.phoneNumber,
          email: data.email,
          areacode: 0,
          local: 0,
          addedOn: "",
        };
        const apiUrl = `main/dncdnm/add?projId=${projectId}&campaignId=${campaignId}&addToMaster=false`;
        const response = await callApi(
          apiUrl,
          HttpMethod.POST,
          newPdncSearchDTO,
        );
        console.log("API Response:", response);
        if (response.type === "SUCCESS") {
          //successfully added to DNC Database
        } else {
          setWarningMessage("Failed to add to DNC Database.");
        }
      }
      //setActiveComponent("InternalDNC");
    } catch (error) {
      setWarningMessage("Failed to save campaign settings.");
      console.error("Failed to save campaign settings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSendPolicy = useCallback(async () => {
    setIsLoading(true);
    setWarningMessage("");
    try {
      const apiUrl = `main/SendPolicy/Send?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setCanPerformAction(response.canPerformAction);
    } catch (error) {
      console.error("Failed to fetch send policy:", error);
      setCanPerformAction(false);
      setWarningMessage("Failed to load send policy.");
    } finally {
      setIsLoading(false);
    }
  }, [projectId, campaignId]);

  useEffect(() => {
    if (campaignId && projectId) {
      fetchSendPolicy();
    }
  }, [projectId, campaignId, fetchSendPolicy]);

  const sentMethod = watch("sentMethod", 0);

  return (
    <>
      <div className="card">
        <div className="card-header">Send the DNC Policy for this Project</div>
        <div className="card-body">
          {isLoading ? <Loading /> : null}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Warning message={warningMessage} />
            <input
              {...register("projId")}
              type="hidden"
              className="form-control"
            />
            <input
              {...register("campaignId")}
              type="hidden"
              className="form-control"
            />
            <div className="row pb-2">
              <div className="col-4 text-end mt-2">
                <label className="pe-3">
                  <input
                    {...register("sentMethod", { required: true })}
                    type="radio"
                    className="me-1"
                    value="0"
                    checked={Number(sentMethod) === 0}
                    disabled={!canPerformAction}
                  />
                  Mail
                </label>
                <label>
                  <input
                    {...register("sentMethod", { required: true })}
                    type="radio"
                    className="me-1"
                    value="1"
                    disabled={!canPerformAction}
                  />
                  E-Mail:
                </label>
                :
              </div>
              <div className="col-8">
                <input
                  {...register("email", { required: sentMethod === 1 })}
                  type="text"
                  className="form-control"
                  disabled={Number(sentMethod) !== 1}
                />
                {errors.email && (
                  <span className="text-danger">Email is required.</span>
                )}
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">Name:</div>
              <div className="col-8">
                <input
                  {...register("name", { required: true })}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">* Address 1:</div>
              <div className="col-8">
                <input
                  {...register("address1", { required: true })}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
                {errors.address1 && (
                  <span className="text-danger">Address 1 is required.</span>
                )}
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">Address 2:</div>
              <div className="col-8">
                <input
                  {...register("address2")}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">* City:</div>
              <div className="col-8">
                <input
                  {...register("city", { required: true })}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
                {errors.city && (
                  <span className="text-danger">City is required.</span>
                )}
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">* State:</div>
              <div className="col-8">
                <select {...register("state")} className="form-select">
                  {states.map((state) => (
                    <option key={state.abbrev} value={state.abbrev}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">* Zip/Postal Code:</div>
              <div className="col-8">
                <input
                  {...register("postCode", { required: true })}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
                {errors.postCode && (
                  <span className="text-danger">
                    Zip/Postal Code is required.
                  </span>
                )}
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">Country:</div>
              <div className="col-8">
                <input
                  {...register("country")}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">Phone Number:</div>
              <div className="col-8">
                <input
                  {...register("phoneNumber")}
                  type="text"
                  className="form-control"
                  disabled={!canPerformAction}
                />
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-4 text-end mt-2">&nbsp;</div>
              <div className="col-8">
                <input
                  {...register("addedToDNC")}
                  type="checkbox"
                  className="me-2"
                  disabled={!canPerformAction}
                />
                Add to the Do Not Call Database of this Project
              </div>
            </div>

            <div className="row pb-2">
              <div className="col-4 text-end mt-2">&nbsp;</div>
              <div className="col-8">
                <button type="submit" className="btn btn-primary me-3">
                  Submit
                </button>
                <button type="reset" className="btn btn-danger">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendPolicy;
