import React, { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import IPoliciesSent from "src/interfaces/Reports/IPoliciesSent";

const PoliciesSent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [policiesSent, setPoliciesSent] = useState<IPoliciesSent[]>([]);

  useEffect(() => {
    const fetchPolicies = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `reports/PoliciesSent`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        setPoliciesSent(response);
      } catch (error) {
        console.error("Failed to fetch send policy:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPolicies();
  }, []);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {!isLoading && (
        <div className="ps-5 pe-5 pt-2">
          <h4>Policies Sent</h4>
          <table className="table">
            <thead>
              <tr>
                <th>Account Code</th>
                <th>Policy from Project</th>
                <th>Sent By</th>
                <th>Sent To</th>
                <th>Sent Via</th>
                <th>State</th>
                <th>Added to DNC?</th>
                <th>Date Sent</th>
              </tr>
            </thead>
            <tbody>
              {policiesSent.map((policy, index) => (
                <tr key={index}>
                  <td>{policy.forAcctId}</td>
                  <td>{policy.policyFromProject}</td>
                  <td>{policy.sentBy}</td>
                  <td>{policy.sentTo}</td>
                  <td>{policy.sentMethod}</td>
                  <td>{policy.state}</td>
                  <td>{policy.addedToDNC}</td>
                  <td>{policy.dateSent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PoliciesSent;
