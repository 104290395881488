import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios, { AxiosProgressEvent } from "axios";
import { useUploadListContext } from "src/contexts/UploadListContext";
import styles from "./UploadFile.module.css";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

const UploadFile: React.FC = () => {
  const { csrfToken } = useAppSelector(selectAuth);
  const { campaignId } = useUploadListContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //AK - Check access
    const fetchUploadFileAccess = async () => {
      try {
        const apiUrl = `sessions/ShowUpload?campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        if (response.message === "Yes") {
          setShowComponent(true);
        }
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    setIsLoading(true);
    if (campaignId !== "") {
      fetchUploadFileAccess();
    }
    setIsLoading(false);
  }, [campaignId]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const formData = new FormData();
      formData.append("campaignId", campaignId);
      formData.append("handler", "scrub_upload");
      formData.append("CSRFToken", csrfToken);
      formData.append("files[]", file);

      // Send the request
      axios({
        method: "post",
        url: "https://www.dncscrub.com/app/main/XFileCallbackPOST",
        data: formData,
        withCredentials: true, // This will ensure cookies are sent with the request - Tcl App needs this!
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const total = progressEvent.total || progressEvent.loaded; // Safe fallback
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / total,
          );
          setUploadProgress(percentCompleted);
        },
      })
        .then((response) => {
          console.log("File uploaded:", response.data);
          setUploadProgress(0);
        })
        .catch((error) => {
          console.error("There was an error uploading the file:", error);
          setUploadProgress(0);
        });
    },
    [campaignId, csrfToken],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });
  if (isLoading) {
    return <Loading />;
  }

  if (!showComponent) {
    return null;
  }
  return (
    <>
      <h2>Upload List and Scrub Results</h2>
      <div className="d-flex gap-2 mt-4 flex-wrap">
        <div
          {...getRootProps()}
          className={`col ${styles["upload-file-container"]}`}
        >
          <input {...getInputProps()} />
          <i className={`bi bi-box-arrow-up ${styles["upload-list-icon"]}`}></i>
          <span className="text-center">UPLOAD LIST</span>
        </div>

        <div className={`col ${styles["scrub-instruction-txt"]}`}>
          The first column of the file should be the phone number. You may
          upload either text or zipped text files. Textfiles must end in .csv or
          .txt. Need help? Contact our Help Desk.
        </div>
      </div>
      <button
        className="download-file-available mt-3 d-none"
        onClick={() => onDrop([])}
      >
        Upload Data
      </button>

      {uploadProgress > 0 && (
        <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
          <div
            style={{
              height: "24px",
              backgroundColor: "blue",
              width: `${uploadProgress}%`,
            }}
          >
            {uploadProgress}%
          </div>
        </div>
      )}
    </>
  );
};

export default UploadFile;
