import React, { useEffect, useState } from "react";
import regionsData from "src/data/regionsUSA.json";

interface Props {
  selectedStates: { state: string; stateName: string }[];
  onStatesChange: (newState: { state: string; stateName: string }[]) => void;
  onStatesFetchedCount?: (count: number) => void;
  initialFetchBehavior?: "all" | "none";
  styleStateWrapper?: string;
  callback?: (
    currentSelectRegions: { state: string; stateName: string }[],
  ) => void;
}

const State: React.FC<Props> = ({
  selectedStates,
  onStatesChange,
  onStatesFetchedCount,
  initialFetchBehavior,
  styleStateWrapper,
  callback,
}) => {
  const [states, setStates] = useState<{ state: string; stateName: string }[]>(
    [],
  );

  useEffect(() => {
    async function fetchStates() {
      try {
        const federalRecords = [
          { state: "FCC", stateName: "Federal (FCC)" },
          { state: "FTC", stateName: "Federal (FTC)" },
        ];

        const dataFromJson = regionsData.map((region) => ({
          state: region.abbrev,
          stateName: region.name,
        }));

        const fetchedData = [...federalRecords, ...dataFromJson];

        setStates(fetchedData);

        if (initialFetchBehavior === "all") {
          onStatesChange(fetchedData);
        } else if (initialFetchBehavior === "none") {
          onStatesChange([]);
        } else {
          // Do nothing for custom behavior or if prop is not provided.
        }

        if (onStatesFetchedCount) {
          onStatesFetchedCount(fetchedData.length);
        }
      } catch (err) {
        console.error(err);
      }
    }
    fetchStates();
  }, [initialFetchBehavior, onStatesChange, onStatesFetchedCount]);

  const handleStateCheckboxChange = (
    state: string,
    stateName: string,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      onStatesChange([...selectedStates, { state, stateName }]);
      callback && callback([...selectedStates, { state, stateName }]);
    } else {
      onStatesChange(selectedStates.filter((s) => s.state !== state));
      callback && callback(selectedStates.filter((s) => s.state !== state));
    }
  };

  const handleCheckAllStates = () => {
    const allStates = states.map((state) => ({
      state: state.state,
      stateName: state.stateName,
    }));
    onStatesChange(allStates);
    callback && callback(allStates);
  };

  const handleUncheckAllStates = () => {
    onStatesChange([]);
    callback && callback([]);
  };

  return (
    <>
      <div>
        <button
          className="btn btn-primary btn-sm"
          onClick={handleCheckAllStates}
        >
          Select All
        </button>
        &nbsp;
        <button
          className="btn btn-primary btn-sm"
          onClick={handleUncheckAllStates}
        >
          Select None
        </button>
        <br />
        <div className={styleStateWrapper}>
          {" "}
          {/*Adding this dev here can style with css selector  */}
          {states.map((stateItem) => (
            <div key={stateItem.state} className="topic-checkbox px-2">
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={stateItem.state}
                  checked={selectedStates.some(
                    (s) => s.state === stateItem.state,
                  )}
                  onChange={(e) =>
                    handleStateCheckboxChange(
                      stateItem.state,
                      stateItem.stateName,
                      e.target.checked,
                    )
                  }
                />
                &nbsp;
                {stateItem.stateName}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default State;
