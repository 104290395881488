import React, { useState, useEffect } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import allStates from "./allStates.json";
import Loading from "src/components/Loading";
import styles from "./MapChart.module.css";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

interface MapChartProps {
  mapId: number;
}

interface Offset {
  [key: string]: [number, number];
}

const offsets: Offset = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const MapChart: React.FC<MapChartProps> = ({ mapId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stateColors, setStateColors] = useState<{ [state: string]: string }>(
    {},
  );

  useEffect(() => {
    setIsLoading(true);
    async function ShowMap() {
      try {
        let apiUrl = `complianceguide/dncmaps?mapdata=${mapId}`;
        const fetchedDataString = await callApi(apiUrl, HttpMethod.GET);
        const fetchedData = JSON.parse(fetchedDataString);
        const colorMap: { [state: string]: string } = {};

        fetchedData.forEach((item: { state: string; Color: string }) => {
          colorMap[item.state] = item.Color || "#FFF"; // Default to #FFF if no color is found
        });

        setStateColors(colorMap);

        // Debugging logs:
        //console.log("Fetched Data:", fetchedData);
        //console.log("Transformed Color Map:", colorMap);
        setIsLoading(false);
      } catch (err) {
        const errorMessage = (err as Error).message;
        console.log(errorMessage);
        setIsLoading(false);
      }
    }

    ShowMap();
  }, [mapId]);

  const getStateColor = (geoId: string): string => {
    const stateAbbreviation = allStates.find((s) => s.val === geoId)?.id;
    console.log(geoId, stateAbbreviation);
    return stateAbbreviation
      ? stateColors[stateAbbreviation] || "#FFFF00"
      : "#FFF";
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.mapContainer}>
          <ComposableMap projection="geoAlbersUsa">
            <Geographies geography={geoUrl}>
              {({ geographies }) => (
                <>
                  {geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      stroke="#000"
                      geography={geo}
                      fill={getStateColor(geo.id)}
                    />
                  ))}
                  {geographies.map((geo) => {
                    const centroid = geoCentroid(geo);
                    const cur = allStates.find((s) => s.val === geo.id);
                    return (
                      <g key={geo.rsmKey + "-name"}>
                        {cur &&
                          centroid[0] > -160 &&
                          centroid[0] < -67 &&
                          (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                            <Marker coordinates={centroid}>
                              <text
                                y="2"
                                fontSize={12}
                                fontWeight={"bold"}
                                textAnchor="middle"
                              >
                                {cur.id}
                              </text>
                            </Marker>
                          ) : (
                            <Annotation
                              subject={centroid}
                              dx={offsets[cur.id][0]}
                              dy={offsets[cur.id][1]}
                              connectorProps={{
                                stroke: "#000", // you can customize this color or add other properties
                                strokeWidth: 1,
                              }}
                            >
                              <text
                                x={4}
                                fontSize={14}
                                alignmentBaseline="middle"
                              >
                                {cur.id}
                              </text>
                            </Annotation>
                          ))}
                      </g>
                    );
                  })}
                </>
              )}
            </Geographies>
          </ComposableMap>
        </div>
      )}
    </>
  );
};

export default MapChart;
