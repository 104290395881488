import React, { useCallback } from "react";
import { ComplianceWizardQuestionData } from "src/interfaces/ComplianceGuide/ComplianceWizardQuestionData";
import questionsData from "./Questions.json";
import State from "src/components/ComplianceGuide/State";

interface CWQuestionProps {
  column: string;
  value: string;
  onChange: (column: string, value: any) => void; // Changed the second parameter to any
  answers?: Record<string, string>;
  selectedStates?: { state: string; stateName: string }[];
}

const data: ComplianceWizardQuestionData[] =
  questionsData as ComplianceWizardQuestionData[];

const CWQuestion: React.FC<CWQuestionProps> = ({
  column,
  value,
  onChange,
  answers,
  selectedStates,
}) => {
  const selectedRegions = selectedStates || []; // Use selectedStates from props
  const entry = data.find((item) => item.column === column);

  // Logs the count of fetched states. Is passed as a prop to <State /> component.
  // useCallback has to be used to stablize the function reference or the State
  // components useEffect will run in loop forever.
  const onStatesFetchedCount = useCallback((count: number) => {
    console.log("Fetched count:", count);
  }, []);

  if (!entry) {
    return null;
  }

  return (
    <>
      <div className="compliance-card-container">
        <div className="row">
          <div className="compliance-card h-100">
            <div className="card-block p-3">
              <p className="card-text">{entry.question}</p>
              <p className="card-text ps-3">
                {entry.values.map((val, index) => (
                  <div className="form-check form-check-inline" key={index}>
                    <label key={index}>
                      <input
                        className="me-2"
                        type="radio"
                        id={`${entry.column}-${index}`}
                        name={entry.column}
                        value={val}
                        checked={value === val}
                        onChange={() => onChange(column, val)}
                        disabled={val === "Limited States"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`${entry.column}-${index}`}
                      >
                        {val}
                      </label>
                    </label>
                  </div>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>

      {column === "size" && value === "Limited States" && (
        <>
          <State
            onStatesChange={(regions) => onChange("selectedStates", regions)} // Changed to propagate back to Wizard
            selectedStates={selectedRegions}
            onStatesFetchedCount={onStatesFetchedCount}
            initialFetchBehavior="none"
          />
        </>
      )}
      {column === "inboundUpsell" && value === "Yes" && (
        <>
          <CWQuestion
            column="inboundUpsellType"
            value={answers?.["inboundUpsellType"] || ""}
            onChange={onChange}
          />
        </>
      )}
      {column === "outboundUpsell" && value === "Yes" && (
        <>
          <CWQuestion
            column="outboundUpsellType"
            value={answers?.["outboundUpsellType"] || ""}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};

export default CWQuestion;
