import React, { useEffect } from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import Citation from "src/components/ComplianceGuide/Citation";
import SafeHtml from "src/components/SafeHtml";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";

const Definitions: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className="regulatory-contents">
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }

  let records = [];
  try {
    records = JSON.parse(data);
  } catch (error) {
    console.error("Error parsing the data:", error);
  }

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
      {records.map((record: any, index: number) => (
        <div key={index} className="regulatory-contents">
          <RegulatoryGuideSection
            label={record.topic}
            value={<SafeHtml html={record.def} />}
          />
          <RegulatoryGuideSection
            label="Citation"
            value={
              <Citation
                statuteId={record.defStatuteId}
                statuteName={record.defStatuteNumber}
                ref1={record.defRef1}
                ref2={record.defRef2}
                ref3={record.defRef3}
              />
            }
          />
        </div>
      ))}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </>
  );
};

export default Definitions;
