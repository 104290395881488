import React from "react";

interface RegulatoryGuideSectionHeaderProps {
  label: string;
}

const RegulatoryGuideSectionHeader: React.FC<
  RegulatoryGuideSectionHeaderProps
> = ({ label }) => {
  return <h6>{label}</h6>;
};

export default RegulatoryGuideSectionHeader;
