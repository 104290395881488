import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import { Campaign } from "src/interfaces/Campaign";
import { useUploadListContext } from "src/contexts/UploadListContext";
import styles from "./TreeView.module.css";

interface AddNewCampaignProps {
  projectId: string;
}

const AddNewCampaign: React.FC<AddNewCampaignProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState("");
  const { setCampaignId, setCampaignName, setRefreshTree } =
    useUploadListContext();
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddNewCampaignClick = async () => {
    if (!newCampaignName.trim()) {
      setErrorMessage("Campaign name cannot be empty");
      inputRef.current?.focus();
      return;
    }

    setErrorMessage("");
    let apiUrl = `main/Campaigns/AddNewCampaign?projId=${projectId}&name=${newCampaignName}`;

    try {
      const apiResponse = await callApi(apiUrl, HttpMethod.GET);
      if (apiResponse && apiResponse.message === "success") {
        const newCampaignId = apiResponse.newCampaignId; // Assuming the response contains the new ID
        const newCampaign: Campaign = {
          id: newCampaignId,
          name: newCampaignName || "Unnamed",
        };

        setCampaignId(newCampaign.id.toString());
        setCampaignName(newCampaign.name);
        setRefreshTree(true);
        navigate(`/main/upload-list/${newCampaignId}`);
      } else {
        console.error("Error creating new campaign:", apiResponse);
      }
    } catch (error) {
      console.error("Error calling API to add new campaign:", error);
    }
  };

  return (
    <>
      {!isAdding ? (
        <div
          className={`${styles["add-campaign-link"]} ${styles["show-hand"]}`}
          onClick={() => setIsAdding(true)}
        >
          + Add new Campaign
        </div>
      ) : (
        <div className={styles["add-campaign-input"]}>
          <input
            type="text"
            placeholder="Campaign name..."
            value={newCampaignName}
            onChange={(e) => setNewCampaignName(e.target.value)}
            className="form-control"
          />
          <button
            type="button"
            onClick={handleAddNewCampaignClick}
            className="btn btn-xs btn-primary"
          >
            + Add
          </button>
          {errorMessage && (
            <div className="bg-white text-center">{errorMessage}</div>
          )}
        </div>
      )}
    </>
  );
};

export default AddNewCampaign;
