import React, { useState, useEffect, useCallback } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { CanadaDNCSettings } from "src/types/CanadaDNCSettings";
import { useForm } from "react-hook-form";
import Warning from "src/components/Warning";
import IFtcInfo from "src/interfaces/Main/IFtcInfo";

const CanadaDNC = () => {
  const { projectId, campaignId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CanadaDNCSettings>();
  const [ftcInfo, setFtcInfo] = useState<IFtcInfo | null>(null);
  const san = watch("San");
  const sanExpiry = watch("SanExpiry");
  const ranPassword = watch("RanPassword");
  const renderFtcColumn = ftcInfo !== null;

  const fetchCanadaDNCData = useCallback(async () => {
    setIsLoading(true);
    try {
      const apiUrl = `main/Projects/GetCanadaDNCSettings?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setFtcInfo(response.FtcInfo);
      const canadaDNC = response.CanadaDNC;
      Object.keys(canadaDNC).forEach((key) => {
        setValue(key as keyof CanadaDNCSettings, canadaDNC[key]);
      });
    } catch (error) {
      console.error("Failed to fetch project data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [projectId, campaignId, setValue]);

  useEffect(() => {
    fetchCanadaDNCData();
  }, [fetchCanadaDNCData]);

  useEffect(() => {
    setValue("SanOption", ranPassword ? "multipleSANs" : "singleSAN");
  }, [ranPassword, setValue]);

  const onSubmit = async (data: CanadaDNCSettings) => {
    setWarningMessage("");
    try {
      const apiUrl = `main/Projects/UpdateCanadaDNCSettings`;
      await callApi(apiUrl, HttpMethod.POST, data);
      fetchCanadaDNCData();
    } catch (error) {
      setWarningMessage("Failed to save settings.");
      console.error("Failed to save settings:", error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <h6>Canada National Do Not Call list</h6>
      <Warning message={warningMessage} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          Enter Canada National DNC list Registration Access Number (RAN):
          <input type="text" className="form-control" {...register("Ran")} />
        </div>
        <input type="hidden" {...register("ProjId")} value={projectId} />
        <div className="container pt-4">
          <div className="row">
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="singleSAN"
                  {...register("SanOption")}
                />
                <span className="ps-2">
                  I want to use a single SAN (most common)
                </span>
              </label>
              <div>
                Enter SAN:
                <input
                  type="text"
                  className={`form-control ${errors.San ? "is-invalid" : ""}`}
                  {...register("San", {
                    maxLength: {
                      value: 10,
                      message: "SAN must be no longer than 10 characters",
                    },
                  })}
                />
                {errors.San && (
                  <div className="invalid-feedback">{errors.San.message}</div>
                )}
              </div>

              <div>
                Enter SAN Download Key:
                <input
                  type="text"
                  className="form-control"
                  {...register("DownloadKey")}
                />
              </div>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="multipleSANs"
                  {...register("SanOption")}
                />
                <span className="ps-2">I want to use multiple SANs</span>
              </label>
              <div>
                Enter RAN login password:
                <input
                  type="text"
                  className="form-control"
                  {...register("RanPassword")}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <table className="table">
              <thead>
                <tr>
                  <td colSpan={3} className="bg-primary text-white">
                    Registration Access Number: {ftcInfo?.OrgId}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SAN(s):</td>
                  {renderFtcColumn && (
                    <td>
                      {san} Expiry: {sanExpiry}
                    </td>
                  )}
                </tr>
                <tr>
                  <td>Provinces Included:</td>
                  {renderFtcColumn && (
                    <td>
                      {ftcInfo?.EntireCountry
                        ? "All of Canada"
                        : "Some provinces"}
                    </td>
                  )}
                </tr>
                <tr>
                  <td>Addl. Area Codes Included:</td>
                  {renderFtcColumn && (
                    <td> {ftcInfo?.EntireCountry ? "" : ftcInfo?.AreaCodes}</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button className="btn btn-primary me-3" type="submit">
          Save
        </button>
      </form>
    </>
  );
};

export default CanadaDNC;
