import React, { useEffect } from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import Citation from "src/components/ComplianceGuide/Citation";
import SafeHtml from "src/components/SafeHtml";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";

const DoNotCallList: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className="regulatory-contents">
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }
  const rec = JSON.parse(data)[0];

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      <div className="regulatory-contents">
        {rec.state !== "FCC" && rec.state !== "FTC" ? (
          <>
            <table className="table table-border content-width-table">
              <tbody>
                <tr>
                  <td>Has State DNC List</td>
                  <td>
                    {rec.hasList === "Yes" || rec.hasList === "DMA"
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="table table-border content-width-table">
              <tbody>
                <tr>
                  <td>Has State DNC List</td>
                  <td>
                    {rec.hasList === "Yes" || rec.hasList === "DMA"
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <td>Has DNC State Law</td>
                  <td>{rec.dncStateLaw ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Law specifies Federal DNC list</td>
                  <td>{rec.dncStateOptFed ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Vertical Industry Exemptions supported by DNCScrub</td>
                  <td>{rec.verticalIndustryExemptions}</td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <div>
            {data && rec.dncUpdateFrequency !== null && (
              <table>
                <tbody>
                  <tr>
                    <td>National DNC List Update Frequency</td>
                    <td>{rec.dncUpdateFrequency}</td>
                  </tr>
                  <tr>
                    <td>Time To Implement</td>
                    <td>{rec.dncTimeToImplement}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
        <RegulatoryGuideSection
          label="Compliance Rule"
          value={<SafeHtml html={rec.complianceRule} />}
        />

        <RegulatoryGuideSection
          label="Citation"
          value={
            <Citation
              statuteId={rec.complianceRuleStatuteId}
              statuteName={rec.complianceRuleStatuteNumber}
              ref1={rec.complianceRuleRef1}
              ref2={rec.complianceRuleRef2}
              ref3={rec.complianceRuleRef3}
            />
          }
        />

        <RegulatoryGuideSection
          label="Implementation Rule"
          value={<SafeHtml html={rec.implementationRule} />}
          hideIfValue={rec.implementationRule}
        />

        {rec.implementationRuleStatuteId && (
          <RegulatoryGuideSection
            label="Citation"
            value={
              <Citation
                statuteId={rec.implementationRuleStatuteId}
                statuteName={rec.implementationRuleStatuteNumber}
                ref1={rec.complianceRuleRef1}
                ref2={rec.complianceRuleRef2}
                ref3={rec.complianceRuleRef3}
              />
            }
          />
        )}
        {data && (rec.dncContact !== null || rec.dncUrl !== null) && (
          <table className="table table-border content-width-table mt-3">
            <tbody>
              <tr>
                <td>Contact</td>
                <td>{rec.dncContact ?? ""}</td>
              </tr>
              <tr>
                <td>URL</td>
                <td>
                  {rec.dncUrl && (
                    <a
                      href={rec.dncUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {rec.dncUrl}
                    </a>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default DoNotCallList;
