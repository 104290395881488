import React, { useEffect, useState } from "react";
import { useUploadListContext } from "src/contexts/UploadListContext";
import IframeComponent from "src/components/IframeComponent";

const ExemptionMaster: React.FC = () => {
  const { campaignId } = useUploadListContext();
  const [iframeSrc, setIframeSrc] = useState("");

  // Abhi - Using useEffect to update the iframe src when the campaignId changes
  useEffect(() => {
    const newSrc = `https://classic.dncscrub.com/app/main/m-legal.adp?campaignId=${campaignId}&reset=1&noSideBar=true`;
    setIframeSrc(newSrc);
  }, [campaignId]);

  return (
    <IframeComponent
      title="Exemption Master"
      src={iframeSrc}
      style={{
        backgroundColor: "var(--neutral-light)", // Keep consistent appearance in dark mode
        width: "100%",
        height: "calc(100vh - var(--height-of-top-nav-bar) - 100px)",
        border: "0",
        zIndex: -1, // Ensure the menu displays on top
      }}
      allowFullScreen
    ></IframeComponent>
  );
};

export default ExemptionMaster;
