import React, { useState, useEffect, useCallback } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpMethod, callApi } from "src/services/apiService";
import ContactInfo from "./ContactInfo";
import CrossAccounts from "./CrossAccounts";
import ClientAccess from "./ClientAccess";
import SubAccounts from "./SubAccounts";

interface TabData {
  key: string;
  title: string;
  path: string;
}

const componentsMap: { [key: string]: React.ComponentType } = {
  contactinfo: ContactInfo,
  crossaccounts: CrossAccounts,
  clientaccess: ClientAccess,
  subaccounts: SubAccounts,
};

const AdminAccountsMain = () => {
  const [tabs, setTabs] = useState<TabData[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTabs = async () => {
      try {
        const apiUrl = "admin/adminsubmenu";
        const response = await callApi(apiUrl, HttpMethod.GET);
        setTabs(response);
      } catch (error) {
        console.error("Failed to fetch access info:", error);
      }
    };
    fetchTabs();
  }, []);

  const getActiveTabKey = useCallback(
    (pathname: string) => {
      const tab = tabs.find((tab) => pathname.endsWith(tab.path));
      return tab ? tab.key : tabs[0]?.key;
    },
    [tabs],
  );

  const initialKey = getActiveTabKey(location.pathname);
  const [key, setKey] = useState(initialKey);

  const handleSelect = (k: string | null | undefined) => {
    const tab = tabs.find((t) => t.key === k);
    if (tab) {
      navigate(tab.path);
    }
  };

  useEffect(() => {
    const newKey = getActiveTabKey(location.pathname);
    if (key !== newKey) {
      setKey(newKey);
    }
  }, [getActiveTabKey, location, key, tabs]);

  return (
    <>
      <div className="mt-5">&nbsp;</div>
      <Tabs
        id="admin-tabs"
        activeKey={key}
        onSelect={handleSelect}
        className="mt-5 mb-3"
      >
        {tabs.map((tab) => (
          <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
            {key === tab.key && componentsMap[tab.key]
              ? React.createElement(componentsMap[tab.key])
              : null}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default AdminAccountsMain;
