import React from "react";

import { Helmet } from "react-helmet-async";
import CGNavBar from "src/pages/ComplianceGuide/NavBar";
import LimitedVersionWarning from "src/pages/ComplianceGuide/LimitedVersionWarning";
import BackToTop from "src/components/BackToTop";

import { useLayoutContext } from "src/contexts/LayoutContext";

import { useAppSelector } from "src/app/hooks";

import Header from "src/components/Header";

type ComplianceGuideLayoutProps = {
  children: React.ReactNode;
};

const ComplianceGuideLayout: React.FC<ComplianceGuideLayoutProps> = ({
  children,
}) => {
  // Get from the Redux store if the user is authenticated
  const isLoggedIn = useAppSelector((state) => state.auth.isAuthenticated);

  const { heading, tagline } = useLayoutContext();

  return (
    <>
      <Helmet>
        <title>DNCScrub: Compliance Guide</title>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
          rel="stylesheet"
        />
      </Helmet>

      <Header />

      <div className="d-flex">
        <CGNavBar />
        <BackToTop />
        <div className="container-fluid maint-content show-scroll-back-to-top">
          <main>
            <div id="compGuideMenuBtn">
              <button
                aria-controls="mobileCompGuideMenuNav"
                className="btn btn-primary submenu-cta"
                data-bs-target="#mobileCompGuideMenuNav"
                data-bs-toggle="offcanvas"
                type="button"
              >
                <i className="bi bi-card-list subnav-menu"></i>{" "}
                <span className="submenu-header">Compliance Guide Menu</span>
              </button>
            </div>
            <div className="compliance-guid-main-container">
              <div>
                <div className="col pagetitle">{heading}</div>

                <div className="row w-100">
                  <div className="col">
                    <p>{tagline}</p>
                  </div>
                </div>

                {children}

                {!isLoggedIn && (
                  <div className="row mt-5">
                    <LimitedVersionWarning />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ComplianceGuideLayout;
