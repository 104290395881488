import React, { useEffect, useState, useCallback, useRef } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import { TCPAAuthoritySessionsProps } from "./Common";
import { ReassignedScrubItem } from "./ReassignedScrubItem";

const ReassignedScrubListItem: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { campaignId } = useUploadListContext();
  const [sessions, setSessions] = useState<TCPAAuthoritySessionsProps[]>([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [shouldStopInterval, setShouldStopInterval] = useState(false);
  const [showNoAccess, setShowNoAccess] = useState(false);
  const utcDateTime = useRef("");

  const refreshInterval =
    (Number(import.meta.env.VITE_APP_REFRESH_INTERVAL_IN_SECONDS) || 5) * 1000;

  const fetchSessions = useCallback(
    async (isInitialLoad = false) => {
      try {
        if (!campaignId) {
          setWarningMessage("There was an issue getting the campaign details.");
          setShouldStopInterval(true);
          setShowNoAccess(true);
          return;
        }

        setWarningMessage("");

        const apiUrl = `sessions/GetTCPAAuthoritySessions?campaignId=${campaignId}`;
        const data = await callApi(apiUrl, HttpMethod.GET);

        if (data && data.length > 0) {
          const fetchedSessions: TCPAAuthoritySessionsProps[] = data;
          if (!utcDateTime.current) {
            utcDateTime.current = new Date().toISOString();
          }

          setSessions((prevSessions) => {
            const sessionMap = new Map(
              prevSessions.map((s) => [s.sessionId, s]),
            );
            fetchedSessions.forEach((fetchedSession) => {
              sessionMap.set(fetchedSession.sessionId!, {
                ...sessionMap.get(fetchedSession.sessionId!),
                ...fetchedSession,
              });
            });
            const newSessions = Array.from(sessionMap.values());
            return newSessions;
          });
          setShouldStopInterval(false);
          setShowNoAccess(false);
        }
      } catch (error) {
        setWarningMessage(
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        );
      }
    },
    [campaignId],
  );

  useEffect(() => {
    setSessions([]);
    utcDateTime.current = "";
  }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      setIsLoading(true);
      fetchSessions(true).finally(() => setIsLoading(false));

      const intervalId = setInterval(() => {
        if (!shouldStopInterval) {
          fetchSessions();
        } else {
          clearInterval(intervalId);
        }
      }, refreshInterval);

      return () => clearInterval(intervalId);
    }
  }, [campaignId, fetchSessions, refreshInterval, shouldStopInterval]);

  return (
    <div style={{ marginTop: "20px" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showNoAccess ? (
            <div>
              <i className="h1 text-danger bi bi-exclamation-triangle pe-3"></i>
              You do not have access to this campaign
            </div>
          ) : (
            <>
              <Warning message={warningMessage} />
              <span className="d-none">Selected Campaign: {campaignId}</span>
              {sessions.length > 0 ? (
                sessions.map((session) => {
                  return (
                    <ReassignedScrubItem
                      key={session.sessionId}
                      session={session}
                    />
                  );
                })
              ) : (
                <div>
                  <p>{"No record found."}</p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ReassignedScrubListItem;
