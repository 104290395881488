import React, { useEffect, useState } from "react";
import { useUploadListContext } from "src/contexts/UploadListContext";
import IframeComponent from "src/components/IframeComponent";

const TrainingMaster: React.FC = () => {
  const { campaignId } = useUploadListContext();
  const [iframeSrc, setIframeSrc] = useState("");

  // Update the iframe source whenever the campaignId changes
  useEffect(() => {
    const newSrc = `https://classic.dncscrub.com/app/main/m-training.adp?campaignId=${campaignId}&noSideBar=true`;
    setIframeSrc(newSrc);
  }, [campaignId]);

  return (
    <IframeComponent
      title="Exemption Master"
      src={iframeSrc}
      style={{
        backgroundColor: "var(--neutral-light)", // Keep consistent appearance in dark mode
        width: "100%",
        height: "calc(100vh - var(--height-of-top-nav-bar))",
        border: "0",
        zIndex: -1, // Ensure the menu displays on top
      }}
      allowFullScreen
    ></IframeComponent>
  );
};

export default TrainingMaster;
