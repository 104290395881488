import React, { useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

const Home = () => {
  const navigate = useNavigate();
  const { role, quickScrub, hasTrainingFeature, homePage } =
    useAppSelector(selectAuth);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const apiUrl = "users/IsAuthenticated";
        const response = await callApi(apiUrl, HttpMethod.GET);

        if (response.message.toLowerCase() === "authorized") {
          if (homePage && role === 1) {
            switch (homePage) {
              case "m-training.adp":
                if (hasTrainingFeature) navigate("/main/training");
                break;
              case "m-quick-scrub.adp":
                if (quickScrub) navigate("/main/quickscrub");
                break;
              case "m-dncdnm-db.adp":
                navigate("/main/internal-dnc");
                break;
              case "upload-list":
                navigate("/main/upload-list");
                break;
            }
          } else {
            // If none of the above worked try the old fashoned way
            if (String(role) === "2" || String(role) === "3") {
              // Agents don't have access to Upload List
              navigate("/main/upload-list");
            } else {
              if (quickScrub) {
                navigate("/main/quickscrub");
              } else if (hasTrainingFeature) {
                navigate("/main/training");
              }
              navigate("/main/internal-dnc");
            }
          }
        }
      } catch (error) {
        console.error("Failed to redirect to the correct homepage:", error);
      }
    };
    fetchAuthStatus();
  }, [navigate, role, quickScrub, hasTrainingFeature, homePage]);

  return (
    <>
      <div>Please wait....</div>
    </>
  );
};

export default Home;
