import React, { useEffect, useState, useCallback, useRef } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import SessionCard from "./SessionCard";
import { Session } from "src/interfaces/Session";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import SafeHtml from "src/components/SafeHtml";
import Styles from "src/components/ScrubWarnings.module.css";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

const Sessions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { campaignId } = useUploadListContext();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [shouldStopInterval, setShouldStopInterval] = useState(false);
  const [showNoAccess, setShowNoAccess] = useState(false);
  const [scrubLimit, setScrubLimit] = useState<string>();
  const utcDateTime = useRef("");
  // Abhi - Reading it once here instead of reading it multiple times in the card component
  const { isSysadmin } = useAppSelector(selectAuth);

  const refreshInterval =
    (Number(import.meta.env.VITE_APP_REFRESH_INTERVAL_IN_SECONDS) || 5) * 1000;

  const fetchSessions = useCallback(
    async (isInitialLoad = false) => {
      try {
        if (!campaignId) {
          setWarningMessage("There was an issue getting the campaign details.");
          setShouldStopInterval(true);
          setShowNoAccess(true);
          return;
        }
        setWarningMessage("");
        let dateFrom = isInitialLoad ? "" : utcDateTime.current;
        let apiUrl = `Sessions/GetSessionRows?campaignId=${campaignId}&dateFrom=${dateFrom}&dateTo=`;
        const data = await callApi(apiUrl, HttpMethod.GET);

        if (data.message) {
          setWarningMessage(data.message);
          if (data.message === "No access") {
            setShouldStopInterval(true);
            setShowNoAccess(true);
          }
          return;
        }

        setScrubLimit(data.scrubLimit ?? "");
        if (data && Array.isArray(data.sessions)) {
          const fetchedSessions: Session[] = data.sessions;
          if (!utcDateTime.current) {
            utcDateTime.current = new Date().toISOString();
          }

          setSessions((prevSessions) => {
            const sessionMap = new Map(
              prevSessions.map((s) => [s.sessionId, s]),
            );
            fetchedSessions.forEach((fetchedSession) => {
              sessionMap.set(fetchedSession.sessionId!, {
                ...sessionMap.get(fetchedSession.sessionId!),
                ...fetchedSession,
              });
            });
            const newSessions = Array.from(sessionMap.values());
            return newSessions;
          });
          setShouldStopInterval(false);
          setShowNoAccess(false);
        } else {
          console.error("Sessions data is not an array:", data);
        }
      } catch (error) {
        setWarningMessage(
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        );
      }
    },
    [campaignId],
  );

  useEffect(() => {
    setSessions([]);
    utcDateTime.current = "";
  }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      setIsLoading(true);
      fetchSessions(true).finally(() => setIsLoading(false));

      const intervalId = setInterval(() => {
        if (!shouldStopInterval) {
          fetchSessions();
        } else {
          clearInterval(intervalId);
        }
      }, refreshInterval);

      return () => clearInterval(intervalId);
    }
  }, [campaignId, fetchSessions, refreshInterval, shouldStopInterval]);

  const removeSession = (sessionId: number) => {
    console.log("Removing session with ID:", sessionId);
    setSessions((prevSessions) =>
      prevSessions.filter((session) => session.sessionId !== sessionId),
    );
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showNoAccess ? (
            <div>
              <i className="h1 text-danger bi bi-exclamation-triangle pe-3"></i>
              You do not have access to this campaign
            </div>
          ) : (
            <>
              <Warning message={warningMessage} />
              {scrubLimit && (
                <div
                  className={`card mt-3 mb-3 position-static ${Styles["scrub-warning-box"]}`}
                >
                  <div className="card-body d-flex align-items-center">
                    <i
                      className={`h1 bi bi-exclamation-triangle-fill pe-3 ${Styles["scrub-warning-icon"]}`}
                    ></i>
                    <SafeHtml html={scrubLimit} />
                  </div>
                </div>
              )}
              <span className="d-none">Selected Campaign: {campaignId}</span>
              {sessions.length > 0 ? (
                sessions
                  .sort((a, b) =>
                    (b.receivedOnGMTSquished ?? "").localeCompare(
                      a.receivedOnGMTSquished ?? "",
                    ),
                  )
                  .map((session) => {
                    return (
                      <SessionCard
                        key={session.sessionId}
                        session={session}
                        onDelete={removeSession}
                        isSysadmin={isSysadmin}
                      />
                    );
                  })
              ) : (
                <div>&nbsp;</div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Sessions;
