import React from "react";
import { CardData } from "src/interfaces/ComplianceGuide/OtherTools/CardData";
import Styles from "./Card.module.css";

const Card: React.FC<CardData> = ({ title, description, externalLink }) => (
  <>
    <div className={Styles["other-tools-card-container"]}>
      <div className="compliance-card h-100">
        <h4 className="compliance-card-title">
          <a href={`${externalLink}`} target="_blank" rel="noreferrer">
            <span>{title}</span>
          </a>
        </h4>
        <p className="card-text">{description}</p>
      </div>
    </div>
  </>
);

export default Card;
