import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import storageService from "src/services/storageService";
import { selectAuth } from "src/features/authSlice";
import { useAppSelector } from "src/app/hooks";

const RouteChangeListener: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { acctId, username } = useAppSelector(selectAuth);

  useEffect(() => {
    // Prevents us from storing last location if it's a NotFound page
    const recordLastLocation = storageService.getItem("storeLastLocation");
    if (recordLastLocation) {
      storageService.removeItem("storeLastLocation");
      return;
    }

    if (!location.pathname.startsWith("/login") && location.pathname !== "/") {
      if (username) {
        const lastLocationKey = `lastLocation_${acctId}_${username}`;
        const fullUrl = location.pathname + location.search;
        storageService.setItem(lastLocationKey, fullUrl);
      }
    }
  }, [acctId, username, location]);

  // console.log(
  //   `Route changed: ${location.pathname} - User: ${storageService.getItem(
  //     "username"
  //   )}`
  // );
  return <>{children}</>;
};

export default RouteChangeListener;
