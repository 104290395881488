import React from "react";
import Sessions from "./Sessions";
import UploadFile from "./UploadFile";
import ScrubWarnings from "./ScrubWarnings";

const UploadList = () => {
  return (
    <>
      <UploadFile />
      <ScrubWarnings />
      <Sessions />
    </>
  );
};

export default UploadList;
