import React from "react";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";

interface UserRoleIconProps {
  Role?: number | string | null;
  Status?: number | null;
}

const UserRoleIcon: React.FC<UserRoleIconProps> = ({ Role, Status = 1 }) => {
  const { roleId } = useAppSelector(selectAuth);

  const getImageSrcByRoleId = (roleId?: number) => {
    switch (roleId) {
      case 1:
        return "/images/roles/user-agent.gif";
      case 2:
        return "/images/roles/user-super.gif";
      case 3:
        return "/images/roles/user-admin.gif";
      case 4:
        return "/images/roles/user-template.gif";
      default:
        return "/images/roles/user-agent.gif";
    }
  };

  // If Role is null then do not show an image.
  if (Role === null) {
    return null;
  }

  let imageSrc: string;

  if (typeof Role === "string") {
    imageSrc = `/images/roles/${Role}`;
  } else {
    const effectiveRoleId = Role ?? roleId;
    imageSrc = getImageSrcByRoleId(effectiveRoleId);
  }

  // Modify the filename based on Status
  if (Status !== 1) {
    imageSrc = imageSrc.replace(".gif", "-inactive.gif");
  }

  return <img src={imageSrc} alt="User Role Icon" />;
};

export default UserRoleIcon;
