import React, { useState } from "react";
import Loading from "src/components/Loading";
import KeyValuePair from "src/interfaces/KeyValuePair";
import { HttpMethod, callApi } from "src/services/apiService";
import { formatNumber } from "src/utils/formatUtils";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { CpaSection } from "src/interfaces/Main/EBRMaster";

interface DownloadEBRDatabaseProps {
  fetchUndoDetails: () => void;
  access: CpaSection | undefined;
}

const DownloadEBRDatabase: React.FC<DownloadEBRDatabaseProps> = ({
  fetchUndoDetails,
  access,
}) => {
  const { projectId, campaignId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(false);
  const [countResults, setCountResults] = useState<KeyValuePair>();

  const handleGetCountButtonClick = async () => {
    setIsLoading(true);
    const apiUrl = `main/ebrmaster/getcount?projId=${projectId}&campaignId=${campaignId}`;
    const response = await callApi(apiUrl, HttpMethod.GET);
    setCountResults(response);
    setIsLoading(false);
  };

  const handleDownload = async () => {
    try {
      const apiUrl = `main/ebrmaster/download?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      const csvData = response.message;
      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      //const date = new Date().toISOString().slice(0, 10);
      const date = new Date().toLocaleDateString("en-CA");
      const fileName = `EBR_${date}_${projectId}.csv`;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the object URL and removing the link element
      window.URL.revokeObjectURL(downloadUrl);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  const handleDeleteAllClick = async () => {
    try {
      setIsLoading(true);
      const apiUrl = `main/ebrmaster/removeall?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.DELETE);
      if (response.type.toLowerCase() === "success") {
        console.log("Successfully deleted all records");
        await handleGetCountButtonClick();
        await fetchUndoDetails();
      } else {
        console.log("An unknown error occurred");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || access === undefined) {
    return <Loading />;
  }

  return (
    <>
      <div className="card mt-3">
        <div className="card-header">Download EBR Database</div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <td>No of Records</td>
                <td>Last Updated</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {countResults ? (
                  <>
                    <td>
                      {formatNumber(parseFloat(countResults?.key ?? "0"))}
                    </td>
                    <td>{countResults?.value}</td>
                  </>
                ) : (
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleGetCountButtonClick()}
                      >
                        Get Count
                      </button>
                    </div>
                  </td>
                )}
                <td>
                  <div className="d-flex gap-3">
                    {access.Download && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleDownload()}
                      >
                        Download
                      </button>
                    )}
                    {access.Clear && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleDeleteAllClick()}
                      >
                        Delete All
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DownloadEBRDatabase;
