import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import IframeComponent from "src/components/IframeComponent";
import { useForm } from "react-hook-form";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import Warning from "src/components/Warning";
import { FtcSan } from "src/types/ftcSan";
import ICpaSection from "src/interfaces/ICpaSection";
import INewProject from "src/interfaces/Main/INewProject";
import IFtcInfo from "src/interfaces/Main/IFtcInfo";
import SaveInProgress from "src/components/SaveInProgress";
import Styles from "./Main.module.css";
import UserRoleIcon from "src/components/UserRoleIcon";

interface ExemptionCategory {
  categoryId: number;
  level: number;
  displayName: string;
  checked: boolean;
}

const Main = () => {
  const navigate = useNavigate();
  const { isSysadmin } = useAppSelector(selectAuth);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setValue,
  } = useForm();
  const allValues = watch();
  const isChecked = watch("project.UsesMasterProjDNCsub");
  const [access, setAccess] = useState<ICpaSection>();
  const [createdByFullName, setCreatedByFullName] = useState<string | null>(
    null,
  );
  const [createdByUserRold, setCreatedByUserRole] = useState<number | null>(
    null,
  );
  const [createdByUserStatus, setCreatedByUserStatus] = useState<number | null>(
    null,
  );
  const [deactivatedByFullName, setDeactivatedByFullName] = useState<
    string | null
  >(null);
  const [deactivatedByUserRole, setDeactivatedByUserRole] = useState<
    number | null
  >(null);
  const [deactivatedByUserStatus, setDeactivatedByUserStatus] = useState<
    number | null
  >(null);
  const [isCrossAccount, setIsCrossAccount] = useState<boolean>(true);
  const [isTrainingAdmin, setIsTrainingAdmin] = useState<boolean>(false);
  const [isNewProject, setIsNewProject] = useState<boolean>(false);
  const [projectCodeNew, setProjectCodeNew] = useState<string>("");
  const [projectNameNew, setProjectNameNew] = useState<string>("");
  const [newProjectData, setNewProjectData] = useState<INewProject | null>(
    null,
  );
  const [ftcInfo, setFtcInfo] = useState<IFtcInfo | null>(null);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [projectData, setProjectData] = useState<string | null>(null);
  const [projectAccountData, setProjectAccountData] = useState<string | null>(
    null,
  );
  const [ftcOrgIdsData, setFtcOrgIdsData] = useState<string | null>(null);
  const [ftcSansData, setFtcSansData] = useState<string | null>(null);
  const {
    projectId,
    setProjectName,
    newProjectName,
    setNewProjectName,
    campaignId,
    setCampaignName,
    setSourceComponent,
    setRefreshTree,
  } = useUploadListContext();

  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [displayIframe, setDisplayIframe] = useState<boolean>(false);
  const showIframe = (url: string) => {
    setIframeUrl(url);
    setDisplayIframe(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [exemptionCategories, setExemptionCategories] = useState<
    ExemptionCategory[]
  >([]);
  const [exemptionsData, setExemptions] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState("");

  const [ftcOrgIdsRec, setFtcOrgIdsRec] = useState<any>(null);
  const [ftcSansRec, setFtcSansRec] = useState<any>(null);

  const resetData = () => {
    setProjectData(null);
    setProjectAccountData(null);
    setFtcOrgIdsData(null);
    setFtcSansData(null);
    setExemptions(null);

    //New
    setCreatedByFullName(null);
    setCreatedByUserRole(null);
    setCreatedByUserStatus(null);
    setDeactivatedByFullName(null);
    setDeactivatedByUserRole(null);
    setDeactivatedByUserStatus(null);
  };

  useEffect(() => {
    //Old data still is showing when the project changes - AK
    resetData();
  }, []);

  // This is exists so when the user closes the FTC Subscription editor
  // the component will re-render and fetch the updated FTC data
  const [popupFTCEditorClosed, setPopupFTCEditorClosed] = useState(false);

  useEffect(() => {
    if (isNewProject) {
      setFtcOrgIdsRec("");
      setFtcSansRec("");
    } else {
      setFtcOrgIdsRec(JSON.parse(JSON.stringify(ftcOrgIdsData)));
      setFtcSansRec(JSON.parse(JSON.stringify(ftcSansData)));
    }
  }, [ftcOrgIdsData, ftcSansData, isNewProject]);

  const fetchAndUpdateProjectData = useCallback(
    async (projId: string, campId: string) => {
      try {
        setWarningMessage(``);
        const apiUrl = `main/Projects/GetProject?projId=${projId}&campaignId=${campId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);

        setProjectData(response.Project);
        setProjectAccountData(response.ProjectAccounts);
        if (isNewProject) {
          setFtcOrgIdsData(null);
          setFtcSansData(null);
        } else {
          setFtcOrgIdsData(null);
          setFtcSansData(response.FtcSans);
        }
        setCreatedByFullName(response.CreatedBy);
        setCreatedByUserRole(response.CreatedByUserRole);
        setCreatedByUserStatus(response.CreatedByUserStatus);
        setDeactivatedByFullName(response.DeactivatedBy);
        setDeactivatedByUserRole(response.DeactivatedByUserRole);
        setDeactivatedByUserStatus(response.DeactivatedByUserStatus);
        setExemptions(response.Exemptions);
        if (
          response.CrossAccount === "true" ||
          response.CrossAccount === true
        ) {
          setIsCrossAccount(true);
        } else {
          setIsCrossAccount(false);
        }
        if (
          response.TrainingAdmin === "true" ||
          response.TrainingAdmin === true
        ) {
          setIsTrainingAdmin(true);
        } else {
          setIsTrainingAdmin(false);
        }
        setAccess(response.CpaSection);
        setFtcInfo(response.FtcInfo);

        const categoriesWithChecked = response.ExemptionCategories.map(
          (category: ExemptionCategory) => ({
            ...category,
            checked: response.SelectedCategories.includes(category.categoryId),
          }),
        );
        setExemptionCategories(categoriesWithChecked);
      } catch (error) {
        setWarningMessage(`Failed to fetch project data`);
        console.error("Failed to fetch project data:", error);
      }
    },
    [isNewProject, popupFTCEditorClosed],
  );

  const fetchNewProjectData = useCallback(async () => {
    const projectDiv = document.querySelector(
      ".show-scroll-back-to-top",
    ) as HTMLElement;
    try {
      setWarningMessage(``);
      setIsNewProject(true);
      setProjectNameNew(newProjectName);
      const apiUrl = `main/Projects/StartNewProject?projId=${projectId}&campaignId=${campaignId}&name=${newProjectName}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response.insert !== true) {
        setWarningMessage(`Failed to fetch project data`);
        return;
      }
      setNewProjectData(response);
      setProjectCodeNew(response.projectCode || "");
      // scroll to top
      projectDiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      //if (response.defaultUseMaster === "true") {
      fetchAndUpdateProjectData(projectId, response.defaultCampaignId);
      setFtcOrgIdsData(null);
      setFtcSansData(null);
      //get rid of extra data

      //}
    } catch (error) {
      setWarningMessage(`Failed to fetch project data`);
      console.error("Failed to fetch project data:", error);
    }
  }, [
    fetchAndUpdateProjectData,
    newProjectName,
    projectId,
    campaignId,
    setProjectNameNew,
  ]);

  useEffect(() => {
    if (campaignId !== "") {
      if (newProjectName !== "") {
        setProjectName(newProjectName);
        setCampaignName("Default Campaign");
        fetchNewProjectData();
        setNewProjectName("");
      } else {
        fetchAndUpdateProjectData(projectId, campaignId);
      }
    }
  }, [
    projectId,
    campaignId,
    newProjectName,
    setProjectName,
    setCampaignName,
    fetchNewProjectData,
    fetchAndUpdateProjectData,
    setNewProjectName,
  ]);

  useEffect(() => {
    //Added this as the data was not updating after API call - AK
    const projectRec = JSON.parse(JSON.stringify(projectData));
    let jsonString = JSON.stringify(projectAccountData);
    const projectAccountRec = JSON.parse(jsonString);
    if (isNewProject) {
      setValue("projectName", projectNameNew);
      setValue("projectCode", projectCodeNew);
      setValue("project.Notes", "");
    } else {
      setValue("project.projId", projectRec?.projId);
      setValue("project.Name", projectRec?.name);
      setValue("project.Notes", projectRec?.notes);
    }
    setValue("project.UsesMasterProjDNCdb", projectRec?.usesMasterProjDNCdb);
    setValue("project.UsesMasterProjEBRdb", projectRec?.usesMasterProjEBRdb);
    setValue(
      "project.UsesMasterProjDNCpolicy",
      projectRec?.usesMasterProjDNCpolicy,
    );
    setValue(
      "project.UsesMasterProjTraining",
      projectRec?.usesMasterProjTraining,
    );
    setValue("project.UsesMasterProjDNCsub", projectRec?.usesMasterProjDNCsub);
    setValue("projectAccount.Id", projectAccountRec?.Id);
    setValue(
      "projectAccount.ScrubSummaryActive",
      projectAccountRec?.ScrubSummaryActive,
    );
    setValue(
      "projectAccount.ScrubSummaryAllProjects",
      projectAccountRec?.ScrubSummaryAllProjects,
    );
    setValue(
      "projectAccount.SupressScrubSummaryIfNoScrubs",
      projectAccountRec?.SupressScrubSummaryIfNoScrubs,
    );
    setValue(
      "projectAccount.ScrubSummaryRecipients",
      projectAccountRec?.ScrubSummaryRecipients,
    );
    setValue(
      "projectAccount.ScrubSummaryFrequency",
      projectAccountRec?.ScrubSummaryFrequency || "1",
    );
    setValue(
      "projectAccount.ScrubSummaryDayOfWeek",
      projectAccountRec?.ScrubSummaryDayOfWeek || "0",
    );
  }, [
    projectData,
    projectAccountData,
    isNewProject,
    projectCodeNew,
    projectNameNew,
    setValue,
  ]);

  if (!projectData || !projectAccountData) {
    return <Loading />;
  }

  const projectRec = JSON.parse(JSON.stringify(projectData));

  let jsonString = JSON.stringify(projectAccountData);
  const projectAccountRec = JSON.parse(jsonString);

  const formatDate = (dateString: string) => {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "";
    }

    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleDeactivateClick = async () => {
    try {
      let apiUrl = `main/Projects/ToggleActiveProject?projectId=${projectId}`;
      const apiResponse = await callApi(apiUrl, HttpMethod.GET);
      if (apiResponse) {
        fetchAndUpdateProjectData(projectId, campaignId);
      }
    } catch (error) {
      console.error("Failed to deactivate project:", error);
    }
  };

  const openPopupWindow = (
    url: string,
    windowName: string,
    windowOptions: string,
  ) => {
    const popupFTCEditor = window.open(url, windowName, windowOptions);
    setPopupFTCEditorClosed(false);
    const checkPopupClosed = () => {
      if (popupFTCEditor && popupFTCEditor.closed) {
        setPopupFTCEditorClosed(true);
        clearInterval(popupCheckInterval);
      }
    };

    const popupCheckInterval = setInterval(checkPopupClosed, 500);
  };

  const handleInputFTCSubscriptionButtonClick = () => {
    const url = `https://core.dncscrub.com/app/main/FTCDNCSubscription?projId=${projectId}`;
    openPopupWindow(url, "Input FTC Subscription", "'width=800,height=600");
  };

  const onSubmit = async (data: any) => {
    setSaveInProgress(true);

    try {
      setWarningMessage(``);
      // Send a request to create a new project
      let newData: any = {};
      let newProjectCode = projectCodeNew;
      let campId = campaignId;
      if (newProjectData?.prefix) {
        newProjectCode = `${newProjectData.parentAcctId}_${projectCodeNew}`;
      }
      newData = {
        projectName: projectNameNew,
        projectCode: newProjectCode,
        isNewProject: true,
      };
      if (isNewProject) {
        const apiUrl = `main/Projects/AddNewProject?projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.POST, newData);
        if (response.type !== "SUCCESS") {
          console.log("New project was not created successfully");
        } else {
          const newCampId = response.message;
          data.project.projId = newProjectCode;
          data.project.Name = projectNameNew;
          data.project.campaignId = newCampId;
          campId = newCampId;
        }
      }
      const apiUrlForUpdate = "main/Projects/UpdateProjectSettings";
      const responseForUpdate = await callApi(
        apiUrlForUpdate,
        HttpMethod.POST,
        data,
      );
      if (responseForUpdate.type === "SUCCESS") {
        //if (data.project.projectName !== projectName) setRefreshTree(true);
        //redirect the user to the new campaign url so that the defaults are loaded correctly.
        setRefreshTree(true);
        navigate(`/main/project-settings/${campId}`);
      } else {
        setWarningMessage(`Failed to save project settings`);
      }
    } catch (error) {
      setWarningMessage(`Failed to save project settings`);
      console.error("Failed to save project settings:", error);
    }

    setSaveInProgress(false);
  };

  const ownershipText = isCrossAccount ? "your" : "its";

  const navigateToScrubOptions = () => {
    setSourceComponent("Project");
    navigate("/main/scrub-options");
  };

  if (!access) return <Loading />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Warning message={warningMessage} />
        <div style={{ display: "none" }}>
          <p>
            Abhi K: Leaving this in place to make it easier to debug. Also need
            hidden fields for save to work
          </p>
          <pre>{JSON.stringify(allValues, null, 2)}</pre>
          <input
            type="hidden"
            {...register("project.projId")}
            value={projectRec?.projId}
          />
          <input
            type="hidden"
            {...register("projectAccount.Id")}
            value={projectAccountRec?.Id}
          />
        </div>
        <br />
        {!displayIframe && (
          <>
            {isNewProject ? (
              <>
                <div className="row">
                  <div className="col-9">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        New Project Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          id="projectName"
                          {...register("projectName", {
                            required: true,
                            onChange: (e) => setProjectNameNew(e.target.value),
                          })}
                          value={projectNameNew}
                          className={`col-3 form-control ${errors.projectName ? "is-invalid" : ""}`}
                        />
                        {errors.projectName && (
                          <div className="invalid-feedback">
                            Project Name is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        New Project Code
                      </label>
                      <div className="col-sm-9">
                        {newProjectData?.prefix && (
                          <div>{newProjectData.parentAcctId}_</div>
                        )}
                        <input
                          id="projectCode"
                          {...register("projectCode", {
                            required: "Project Code is required",
                            validate: {
                              //noSpaces: (value) => !/\s/.test(value) || "No spaces allowed",
                              noLeadingNumber: (value) =>
                                !/^\d/.test(value) ||
                                "Cannot start with a number",
                              alphanumeric: (value) =>
                                /^[a-zA-Z0-9]*$/.test(value) ||
                                "Only alphanumeric characters are allowed",
                            },
                            onChange: (e) => setProjectCodeNew(e.target.value),
                          })}
                          value={projectCodeNew}
                          className={`col-3 form-control ${errors.projectCode ? "is-invalid" : ""}`}
                        />
                        {errors.projectCode && (
                          <div className="invalid-feedback">
                            {typeof errors.projectCode.message === "string" &&
                              errors.projectCode.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!projectAccountRec.IsMaster && (
                  <>
                    {projectRec.status === 1 && access.deactivate === true && (
                      <>
                        <h6>Deactivate Project:</h6>
                        <p>
                          You can deactivate a project to hide it from a list
                          and prevent it from being retroactively scrubbed.
                        </p>
                        <button
                          className="btn btn-danger mt-2"
                          onClick={handleDeactivateClick}
                        >
                          Deactivate
                        </button>
                      </>
                    )}
                    {projectRec.status === 2 && access.reactivate === true && (
                      <>
                        <h6>This project is deactivated.</h6>
                        <p>
                          You can reactivate a project to show it in a list and
                          have it be retroactively scrubbed.
                        </p>
                        <button
                          className="btn btn-secondary mt-2"
                          onClick={handleDeactivateClick}
                        >
                          Reactivate
                        </button>
                      </>
                    )}

                    <hr />
                    <h6>Rename Project:</h6>
                    <input
                      {...register("project.Name", { required: true })}
                      className={`form-control ${
                        errors["project.Name"] ? "is-invalid" : ""
                      }`}
                    />
                    {errors["project.Name"] && (
                      <span>Project name is required</span>
                    )}

                    <h6>Project Code: </h6>
                    {projectId}

                    <div className="mt-3 mb-3">
                      <button type="submit" className="btn btn-primary ms-2">
                        Save
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
            {!isNewProject && (
              <div className="mb-3">
                {createdByFullName && projectRec.createdOn && (
                  <div className="d-flex align-items-start">
                    <div className="col d-flex align-items-center justify-content-start">
                      <span className="fw-bold">Created By:</span>
                      <span className="ms-2 d-flex align-items-center">
                        <UserRoleIcon
                          Role={createdByUserRold}
                          Status={createdByUserStatus}
                        />
                        <span className="ms-1">
                          {createdByFullName} on{" "}
                          {formatDate(projectRec.createdOn)}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                {projectRec.status === 2 &&
                  deactivatedByFullName &&
                  projectRec.deactivatedOn && (
                    <div className="d-flex align-items-start">
                      <div className="col d-flex align-items-center justify-content-start">
                        <span className="fw-bold">Deactivated By:</span>
                        <span className="ms-2 d-flex align-items-center">
                          <UserRoleIcon
                            Role={deactivatedByUserRole}
                            Status={deactivatedByUserStatus}
                          />
                          <span className="ms-1">
                            {deactivatedByFullName} on{" "}
                            {formatDate(projectRec.deactivatedOn)}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            )}

            <h6>Project Notes: </h6>
            <textarea
              {...register("project.Notes")}
              className={`form-control`}
            />

            {!isNewProject && !projectAccountRec.IsMaster && (
              <>
                <hr />
                <label>
                  <input
                    type="checkbox"
                    {...register("project.UsesMasterProjDNCdb")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's DNC/DNM
                    database.
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    {...register("project.UsesMasterProjEBRdb")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's EBRMaster
                    Override database.
                  </span>
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    {...register("project.UsesMasterProjDNCpolicy")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's DNC
                    Policy.
                  </span>
                </label>
                {isTrainingAdmin && (
                  <>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        {...register("project.UsesMasterProjTraining")}
                        disabled={isCrossAccount}
                      />
                      <span className="ps-2">
                        This project uses {ownershipText} Master Project's
                        Training Courses.
                      </span>
                    </label>
                  </>
                )}
                <br />
                <label>
                  <input
                    type="checkbox"
                    {...register("project.UsesMasterProjDNCsub")}
                    disabled={isCrossAccount}
                  />
                  <span className="ps-2">
                    This project uses {ownershipText} Master Project's National
                    DNC Subscription.
                  </span>
                </label>
                <br />
              </>
            )}
            {!isChecked && (
              <>
                <hr />
                <div className="card">
                  <div className="card-header">
                    US National DNC Subscription
                  </div>
                  <div className="card-body">
                    <p>
                      National DNC Registry Organization Id:{" "}
                      <b>{ftcInfo?.OrgId}</b>
                    </p>
                    <p>
                      To provide or update your National DNC Subscription,
                      please email <b>support@dnc.com</b> with your Organization
                      ID and SAN
                    </p>
                    {isSysadmin && (
                      <button
                        className="btn btn-primary"
                        onClick={handleInputFTCSubscriptionButtonClick}
                      >
                        Input FTC Subscription
                      </button>
                    )}
                    <table className="table mt-3">
                      <thead>
                        <tr>
                          <th colSpan={2}>Org Id: {ftcInfo?.OrgId}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>SAN(s):</td>
                          <td>
                            <ul className={Styles["san-list-style"]}>
                              {ftcSansRec &&
                                ftcSansRec.map(
                                  (record: FtcSan, index: number) => (
                                    <li key={index}>
                                      {record.San}
                                      {record.Expiration && (
                                        <span className="ps-3">
                                          Expires:{" "}
                                          {formatDate(record.Expiration)}
                                        </span>
                                      )}
                                    </li>
                                  ),
                                )}
                            </ul>
                          </td>
                        </tr>

                        <tr>
                          <td>States Included:</td>
                          <td>
                            {ftcInfo?.EntireCountry
                              ? `Entire ${ftcInfo?.CountryName || "United States"}`
                              : ftcInfo?.States || ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Addl. Area Codes Included:</td>
                          <td>
                            {ftcInfo?.EntireCountry ? "" : ftcInfo?.AreaCodes}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            <div className="card mt-3">
              <div className="card-header">Scrub Summary Report</div>
              <div className="card-body">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      {...register("projectAccount.ScrubSummaryActive")}
                    />
                    <span className="ps-2">
                      Enable Scrub Summary report for this Project
                    </span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      {...register("projectAccount.ScrubSummaryAllProjects")}
                    />
                    <span className="ps-2">
                      Include all projects in the summary report
                    </span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      {...register(
                        "projectAccount.SupressScrubSummaryIfNoScrubs",
                      )}
                    />
                    <span className="ps-2">
                      Only send summary report on days when scrubs have occurred
                    </span>
                  </label>
                </div>
                <br />
                <h6>E-mail reports To:</h6>
                <textarea
                  {...register("projectAccount.ScrubSummaryRecipients")}
                  className={`form-control`}
                />
                <h6>Frequency of updates:</h6>
                <select
                  {...register("projectAccount.ScrubSummaryFrequency")}
                  className="form-control form-select"
                >
                  <option value="1">Daily</option>
                  <option value="7">Every Week</option>
                </select>
                <h6>If weekly, day of week to receive report:</h6>
                <select
                  {...register("projectAccount.ScrubSummaryDayOfWeek")}
                  className="form-control form-select"
                >
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </select>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-header">IntelliScrub Settings</div>
              <div className="card-body">
                This Project is for a company in these product categories.
                <br />
                {exemptionsData && (
                  <div className="bg-warning text-black p-2 mt-2 mb-2">
                    Warning: Your Project settings grant exemptions in these
                    States: <b>{exemptionsData}.</b>
                  </div>
                )}
                <p>Business/Product Categories:</p>
                {exemptionCategories &&
                  exemptionCategories.map((category) => {
                    const paddingClass = `ps-${
                      category.level === 1 ? 1 : category.level + 2
                    }`;

                    return (
                      <div key={category.categoryId} className={paddingClass}>
                        <input
                          type="checkbox"
                          {...register(`categories.${category.categoryId}`)}
                          defaultChecked={category.checked}
                          id={`category-${category.categoryId}`}
                        />
                        <label
                          htmlFor={`category-${category.categoryId}`}
                          className="ps-2"
                        >
                          {category.displayName}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        <br />
        {displayIframe && (
          <>
            <IframeComponent
              title="My Iframe"
              src={iframeUrl || "about:blank"}
              width="100%"
              height="1000px"
              allowFullScreen
              style={{ border: "none" }}
            />
          </>
        )}
        <div className="page-buttons">
          <button
            type="submit"
            className="btn btn-primary me-2"
            disabled={!isDirty}
          >
            Save
          </button>
          <SaveInProgress isVisible={saveInProgress} />
          <button
            className="btn btn-secondary me-2"
            onClick={navigateToScrubOptions}
          >
            Project Details / Default Scrub Options...
          </button>
          <button
            className="btn btn-secondary me-2"
            onClick={() =>
              showIframe(
                `https://core.dncscrub.com/app/main/nocallholidays?projId=${projectId}&campaignId=${campaignId}&nat_dnc_org_id=${ftcOrgIdsRec?.Orgid}`,
              )
            }
          >
            No Call Holidays
          </button>
          <button
            className="btn btn-secondary me-2"
            onClick={() =>
              showIframe(
                `https://core.dncscrub.com/app/main/CustomCallingWindow?projId=${projectId}&campaignId=${campaignId}&nat_dnc_org_id=${ftcOrgIdsRec?.Orgid}`,
              )
            }
          >
            Calling Time Windows
          </button>
        </div>
      </form>
    </>
  );
};

export default Main;
