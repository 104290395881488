import React, { useState } from "react";
import { UseFormRegister, Control } from "react-hook-form";
import { EbrTypeLabels } from "src/interfaces/Main/EBRMaster";

interface EbrRecordRowProps {
  register: UseFormRegister<any>;
  control: Control<any>;
  index: number;
}

const EbrRecordRow: React.FC<EbrRecordRowProps> = ({
  register,
  control,
  index,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({
    fullPhone: null,
    email: null,
  });

  const validatePhone = (value: string) => {
    const cleanValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (!/^\d{10}$/.test(cleanValue)) {
      return "Phone number must be exactly 10 digits without any spaces or special characters";
    }
    if (cleanValue[3] === "0" || cleanValue[3] === "1") {
      return "Invalid phone number";
    }
    return true;
  };

  // Handle phone number changes
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validationResult = validatePhone(event.target.value);
    setErrors((prev) => ({
      ...prev,
      fullPhone: typeof validationResult === "string" ? validationResult : null,
    }));
    event.target.value = event.target.value.replace(/\D/g, "");
  };

  // Validate email
  const validateEmail = (value: string) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return "Invalid email address";
    }
    return true;
  };
  return (
    <tr>
      <td>
        <input
          {...register(`records[${index}].fullPhone`)}
          className="form-control"
          onChange={handlePhoneChange}
          type="text"
        />
        {errors.fullPhone && (
          <div className="error-message">{errors.fullPhone}</div>
        )}
      </td>
      <td>
        <input
          {...register(`records[${index}].dateOfLastContact`)}
          className="form-control"
          type="date"
          placeholder=""
        />
      </td>
      <td>
        <select {...register(`records[${index}].type`)} className="form-select">
          {Object.entries(EbrTypeLabels).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </td>
      <td>
        <input
          {...register(`records[${index}].reference`)}
          className="form-control"
        />
      </td>
      <td>
        <input
          {...register(`records[${index}].brand`)}
          className="form-control"
        />
      </td>
      <td>
        <input
          {...register(`records[${index}].email`, {
            onChange: (e) => {
              const validationResult = validateEmail(e.target.value);
              setErrors((prev) => ({
                ...prev,
                email:
                  typeof validationResult === "string"
                    ? validationResult
                    : null,
              }));
            },
          })}
          className="form-control"
          type="email"
        />
        {errors.email && <div className="error-message">{errors.email}</div>}
      </td>
    </tr>
  );
};

export default EbrRecordRow;
