import React from "react";

export enum ReassignedProducts {
  TCPAReassignedAuthority = 1,
  EnhancedRND,
  BasicRND,
}

const ReassignedProductDetails = {
  [ReassignedProducts.TCPAReassignedAuthority]: "Reassigned Authority",
  [ReassignedProducts.EnhancedRND]: "Reassigned Authority Plus",
  [ReassignedProducts.BasicRND]: "Basic RND",
};

export const ReassignedProductComponent: React.FC<{
  reassignedProductId: number;
}> = React.memo(({ reassignedProductId }) => {
  const product =
    ReassignedProductDetails[reassignedProductId as ReassignedProducts];

  return (
    <h6 className="mb-0 mx-3">
      REASSIGNED PRODUCT:{" "}
      <span className="badge text-bg-secondary"> {product}</span>
    </h6>
  );
});
