import React, {
  createContext,
  useContext,
  useState,
  useRef,
  ReactNode,
} from "react";

interface WizardState {
  currentStep: number;
  answers: Record<string, any>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setAnswers: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  stepComplete: Record<number, boolean>;
  markStepAsComplete: (step: number, isComplete: boolean) => void;
  questionNumber: number;
  incrementQuestionNumber: () => void;
  resetQuestionNumber: () => void;
}

const defaultState: WizardState = {
  currentStep: 1,
  answers: {},
  setCurrentStep: () => {},
  setAnswers: () => {},
  stepComplete: {},
  markStepAsComplete: () => {},
  questionNumber: 1,
  incrementQuestionNumber: () => {},
  resetQuestionNumber: () => {},
};

const WizardContext = createContext<WizardState>(defaultState);

export const useWizard = () => useContext(WizardContext);

interface WizardProviderProps {
  children: ReactNode;
}

export const WizardProvider: React.FC<WizardProviderProps> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [stepComplete, setStepComplete] = useState<Record<number, boolean>>({});

  const questionNumberRef = useRef(1);
  const incrementQuestionNumber = () => (questionNumberRef.current += 1);
  const resetQuestionNumber = () => (questionNumberRef.current = 1);

  const markStepAsComplete = (step: number, isComplete: boolean) => {
    setStepComplete((prev) => ({ ...prev, [step]: isComplete }));
  };

  return (
    <WizardContext.Provider
      value={{
        currentStep,
        answers,
        setCurrentStep,
        setAnswers,
        stepComplete,
        markStepAsComplete,
        questionNumber: questionNumberRef.current,
        incrementQuestionNumber,
        resetQuestionNumber,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

export default WizardProvider;
