import React from "react";

const SubAccounts = () => {
  return (
    <>
      <div>SubAccounts</div>
    </>
  );
};

export default SubAccounts;
