import React from "react";
import { WizardProvider } from "src/contexts/ComplianceGuide/ComplianceWizardContext";
import Wizard from "./Wizard";

const Index = () => {
  return (
    <WizardProvider>
      <Wizard />
    </WizardProvider>
  );
};

export default Index;
