import React from "react";
import { Session } from "src/interfaces/Session";
import { HttpMethod, callApi } from "src/services/apiService";
import Styles from "./SessionCard.module.css";
import { formatNumber, formatDateTime } from "src/utils/formatUtils";
import UserRoleIcon from "src/components/UserRoleIcon";

interface SessionCardProps {
  session: Session;
  onDelete: (sessionId: number) => void;
  isSysadmin: boolean;
}

const statuses = [
  { label: "Importing", icon: "bi-upload" },
  { label: "Queued", icon: "bi-clock-history" },
  { label: "Scrubbing", icon: "bi-clipboard2-check" },
  { label: "Generating Output", icon: "bi-ui-checks-grid" },
  { label: "Completed", icon: "bi-check-circle-fill" },
];

const getClass = (currentStatus: string, sessionStatus?: string | null) => {
  return currentStatus === sessionStatus
    ? `status-${sessionStatus.toLowerCase() ?? "not-status"} current-session-status`
    : "not-status";
};

//
// Render the status row
//
const renderStatusRow = (statuses: any[], sessionStatus?: string | null) => {
  const statusFound = statuses.some((status) => status.label === sessionStatus);

  if (!statusFound) {
    return (
      <div className={`col-11 text-start`}>
        <span className="current-session-status pe-2 ps-2">
          {sessionStatus}
        </span>
      </div>
    );
  } else {
    return statuses.map((status, index) => (
      <div
        key={index}
        className={`col ${getClass(status.label, sessionStatus)}`}
      >
        <i
          className={`bi ${status.icon} status-icon ${getClass(
            status.label,
            sessionStatus,
          )} me-2`}
        ></i>
        <span>{status.label}</span>
      </div>
    ));
  }
};

const SessionCard: React.FC<SessionCardProps> = ({
  session,
  onDelete,
  isSysadmin,
}) => {
  const sessionStatus = session.status;
  const handleDelete = async (sessionId: number) => {
    if (!sessionId) {
      console.error("No session ID found.");
      return;
    }

    try {
      const response = await callApi(
        `Sessions/RemoveSession?sessionId=${sessionId}`,
        HttpMethod.GET,
      );

      if (response) {
        //if response says "OK" then it is deleted.  This session card should be removed
        onDelete(sessionId);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const cssClassForStatsColumn = "col-12 col-md-4 col-lg-4 col-xl-3 d-flex";
  return (
    <>
      {/* If the session is an error, display the error card, else display the scrub status card */}
      {session.rowType === "e" ? (
        <div className={`card mb-3 ${Styles["presession-error-card"]}`}>
          <div className="card-body d-flex gap-3 align-items-center">
            <i className="h1 text-danger bi bi-exclamation-circle-fill"></i>
            <h4 className="text-black">Error</h4>
            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-6">
                <b>Error: </b>
                {session.status}
              </div>
              <div className="col-12 col-md-6">
                <b>File Details: </b>
                {session.originalFilename}
              </div>
              <div className="col-12 col-md-6">
                <b>Uploaded: </b>
                {formatDateTime(session.receivedOn ?? "")}
              </div>
              <div className="col-12 col-md-6">
                <b>Received By: </b>
                {session.fullName}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${Styles["session-card"]} ${session.receivedBy === "W" ? Styles["session-card-web-upload"] : Styles["session-card-sftp-upload"]} card text-center  mb-4`}
        >
          <div className={`${Styles["session-card-header"]} card-header`}>
            <div className="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
              <div className="fw-bold">
                <i className={`bi bi-filetype-csv me-2`}></i>
                {session.originalFilename}
              </div>

              <div className="d-flex align-items-center">
                <i className="bi bi-calendar2 me-2"></i>{" "}
                {formatDateTime(session.receivedOn ?? "")}
              </div>

              <div className="d-flex align-items-center">
                <span className="fw-bold me-1">Uploaded By:</span>
                <UserRoleIcon Role={session.bitmap} />
                {/* This is incorrect and needs to change to the original user that uploaded
                    file which is the  username of Session.userId */}
                {session.fullName}
              </div>

              <div className={`${Styles["upload-method-status"]} fw-bold`}>
                {/* {session.status} */}
                {session.receivedBy === "W" ? "WEB" : "SFTP"}
              </div>
            </div>
          </div>

          {/* Session card body */}
          <div className="card-body">
            {/* Status row */}
            <div className="d-flex align-items-center border-bottom pb-1">
              {/* The d-none and d-lg-block hide the Status text on small screens */}
              <div
                className={`d-none d-lg-block col text-start p-1 ${Styles["status-bar"]}`}
              >
                STATUS:
              </div>
              {renderStatusRow(statuses, sessionStatus)}
            </div>
            <div className={`row ${Styles["completed-session-card"]}`}>
              <div className={cssClassForStatsColumn}>
                <div className="col text-start pt-2">
                  Total Records:
                  <br />
                  Billable:
                  <br />
                  Malformed:
                  <br />
                  VoIP:
                </div>
                <div className="col text-start pt-2">
                  {formatNumber(session.numOfTotalRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfBillableRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfMalformedRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfVoIPRecords ?? 0)}
                </div>
              </div>

              <div className={cssClassForStatsColumn}>
                <div
                  className={`col ${Styles["border-start-sm"]} text-start pt-md-2 ps-md-2`}
                >
                  Clean:
                  <br />
                  Invalid:
                  <br />
                  Blocked:
                </div>
                <div className="col text-start pt-md-2">
                  {formatNumber(session.numOfCleanRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfInvalidRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfBlockedRecords ?? 0)}
                </div>
              </div>

              <div className={cssClassForStatsColumn}>
                <div
                  className={`col ${Styles["border-start-sm"]} text-start pt-md-2 ps-md-2`}
                >
                  EBR:
                  <br />
                  Wireless:
                  <br />
                  Do Not Call:
                  <br />
                </div>
                <div className="col text-start pt-md-2">
                  {formatNumber(session.numOfEBRRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfWirelessRecords ?? 0)}
                  <br />
                  {formatNumber(session.numOfDNCRecords ?? 0)}
                </div>
              </div>

              <div className="col-12 col-xl-3 d-flex">
                <div
                  className={`col ${Styles["border-start-lg"]} text-start pt-2 ps-xl-2 d-flex flex-column flex-sm-row flex-xl-column gap-sm-3 gap-xl-0`}
                >
                  <a
                    target="_top"
                    href={`https://classic.dncscrub.com/app/main/session/download_output?sessionId=${session.sessionId}&handler=download_output&campaignId=${session.campaignId}`}
                    className={`btn btn-primary btn-sm d-flex align-items-center justify-content-center ${Styles["download-button"]}`}
                    type="button"
                    style={{
                      pointerEvents:
                        (session.multiFileOutputFileSize ?? 0) > 0
                          ? "auto"
                          : "none",
                      opacity: (session.multiFileOutputFileSize ?? 0) ? 1 : 0.5,
                    }}
                  >
                    DOWNLOAD OUTPUT{" "}
                    {session.multiFileOutputFileSize
                      ? `(${Number((session.multiFileOutputFileSize / 1024).toFixed()).toLocaleString()}KB)`
                      : ""}
                  </a>
                  {session.outputFileLastDownloaded && (
                    <>
                      <div>
                        <b>Last downloaded:</b> <br />
                        {session.outputFileLastDownloaded}
                      </div>
                      <div>
                        <b>Downloaded by:</b>
                        <div className="d-flex align-items-center">
                          <UserRoleIcon
                            Role={session.outputFileLastDownloadedByBitmap}
                          />
                          {session.outputFileLastDownloadedByName}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Session card footer */}
          <div
            className={`${Styles["session-card-footer-bg-color"]} ${Styles["card-footer"]}`}
          >
            {/* Shown row of scrub details with accordion to show more details */}
            <div id="scrubDetails" className={`d-flex align-items-center`}>
              <div className="col">
                <button
                  // Next action create styles for show-details-link
                  className={`d-flex btn btn-link ${Styles["show-details-link"]} mx-auto align-items-center collapsed`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#scrub-details-${session.sessionId}`}
                  aria-controls="show-details-scrubcard2"
                >
                  SHOW DETAILS
                  <i
                    className={`bi bi-chevron-down ${Styles["show-details-chevron"]} ms-1`}
                  ></i>
                </button>
              </div>

              {isSysadmin && (
                <div className="col">
                  <button
                    className={`btn btn-danger btn-sm`}
                    onClick={() => {
                      if (typeof session.sessionId === "number") {
                        handleDelete(session.sessionId);
                      } else {
                        console.error("Session ID is not a number");
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>

            {/* Accordion to show more details */}
            <div
              id={`scrub-details-${session.sessionId}`}
              className={`accordion-collapse collapse`}
              aria-labelledby="scrubDetails"
            >
              <div className="d-flex align-items-center">
                <div className="col d-flex align-items-center mx-auto justify-content-center">
                  <span className="fw-bold">Last User to Scrub:</span>
                  <div className="pe-1">
                    <span className="ms-1 d-flex">
                      <UserRoleIcon Role={session.lastUserToModifyBitmap} />
                      {session.lastUserToModifyByName}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <span className="fw-bold me-2">Last Scrubbed:</span>
                  {session.timeLastScrubbedGMTSquished}
                </div>

                <div className="col">
                  <span className="fw-bold me-2">
                    Time to Perform Last Scrub:
                  </span>
                  {session.timeLastScrubInSecs
                    ? `${session.timeLastScrubInSecs}s`
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionCard;
