import React, { useEffect } from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import Citation from "src/components/ComplianceGuide/Citation";
import SafeHtml from "src/components/SafeHtml";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";

const SimpleTopic: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (data && data.length > 0) {
          if (div) {
            div.style.display = "block";
          }
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region, topic]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className="regulatory-contents">
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }

  let rec;

  try {
    rec = JSON.parse(data)[0];
    if (!rec || typeof rec !== "object") throw new Error("Invalid data format");
  } catch (error) {
    return <div className="regulatory-contents">Error processing data...</div>;
  }

  const removeStyles = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Find all elements and remove specific styles
    doc.querySelectorAll("*").forEach((element) => {
      // Ensure that the element is of type HTMLElement
      if (element instanceof HTMLElement) {
        if (element.style.fontFamily) {
          element.style.removeProperty("font-family");
        }
        if (element.style.fontSize) {
          element.style.removeProperty("font-size");
        }
        if (element.getAttribute("class") === "ewa-rteLine") {
          element.removeAttribute("style");
          element.removeAttribute("class");
        }
      }
    });

    return doc.body.innerHTML;
  };

  const onlyOneCitationPresent =
    rec.legalStatuteId1 &&
    !rec.legalStatuteId2 &&
    !rec.legalStatuteId3 &&
    !rec.legalStatuteId4 &&
    !rec.legalStatuteId5;
  const styleRemovedOverview = rec.overview ? removeStyles(rec.overview) : "";

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      <div className="regulatory-contents">
        {styleRemovedOverview && (
          <>
            <RegulatoryGuideSection
              label="Compliance Rule"
              value={<SafeHtml html={styleRemovedOverview} />}
            />
            {rec.legalStatuteId1 && (
              <RegulatoryGuideSection
                label={onlyOneCitationPresent ? "Citation" : "Citation 1"}
                value={
                  <Citation
                    statuteId={rec.legalStatuteId1}
                    statuteName={rec.legalStatuteNumber1}
                    ref1={null}
                    ref2={null}
                    ref3={null}
                  />
                }
              />
            )}

            {rec.legalStatuteId2 && (
              <RegulatoryGuideSection
                label="Citation 2"
                value={
                  <Citation
                    statuteId={rec.legalStatuteId2}
                    statuteName={rec.legalStatuteNumber2}
                    ref1={null}
                    ref2={null}
                    ref3={null}
                  />
                }
              />
            )}

            {rec.legalStatuteId3 && (
              <RegulatoryGuideSection
                label="Citation 3"
                value={
                  <Citation
                    statuteId={rec.legalStatuteId3}
                    statuteName={rec.legalStatuteNumber3}
                    ref1={null}
                    ref2={null}
                    ref3={null}
                  />
                }
              />
            )}

            {rec.legalStatuteId4 && (
              <RegulatoryGuideSection
                label="Citation 4"
                value={
                  <Citation
                    statuteId={rec.legalStatuteId4}
                    statuteName={rec.legalStatuteNumber4}
                    ref1={null}
                    ref2={null}
                    ref3={null}
                  />
                }
              />
            )}

            {rec.legalStatuteId5 && (
              <RegulatoryGuideSection
                label="Citation 5"
                value={
                  <Citation
                    statuteId={rec.legalStatuteId5}
                    statuteName={rec.legalStatuteNumber5}
                    ref1={null}
                    ref2={null}
                    ref3={null}
                  />
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SimpleTopic;
