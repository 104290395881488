import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./QuickScrub.module.css";

const QuickScrub = () => {
  const DEFAULT_ROW_HEIGHT = 3;
  const navigate = useNavigate();
  const [text, setText] = useState<string>("");
  const [rows, setRows] = useState<number>(DEFAULT_ROW_HEIGHT);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const maxRows = 5;
  const digitsPerLine = 10;

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = DEFAULT_ROW_HEIGHT;
    const currentRows = Math.floor(
      event.target.scrollHeight / textareaLineHeight,
    );

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  const validateInput = () => {
    // trim to get rid of first or last line blanks
    const trimmedText = text.replace(/^\s+|\s+$/g, "");
    const lines = trimmedText.split("\n");
    let cleanedPhoneList = "";

    for (let i = 0; i < lines.length; i++) {
      const numericLine = lines[i].replace(/\D/g, "");
      // skip empty lines
      if (numericLine.length === 0) {
        continue;
      }
      if (numericLine.length !== digitsPerLine) {
        setErrorMessage(
          `Error on line ${
            i + 1
          }: Line must contain exactly ${digitsPerLine} digits.`,
        );
        textAreaRef.current?.focus();
        const position = lines
          .slice(0, i)
          .reduce((acc, line) => acc + line.length + 1, 0);
        textAreaRef.current?.setSelectionRange(
          position,
          position + lines[i].length,
        );
        return;
      }
      cleanedPhoneList += numericLine + "\n";
    }
    setErrorMessage("");
    // Go to quickscrub results page
    navigate("/main/quickscrub", {
      state: { phoneList: cleanedPhoneList.trim() },
    });
  };

  return (
    <div className={`${Styles["quickscrub-phonebox"]}`}>
      <h6 className="quickscrub-title">
        QuickScrub
        <svg
          className={`bi bi-telephone-fill ${Styles["quickscrub-icon"]}`}
          fill="currentColor"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            fillRule="evenodd"
          ></path>
        </svg>
      </h6>
      <div className="position-relative mt-2">
        <textarea
          rows={rows}
          value={text}
          ref={textAreaRef}
          placeholder="(XXX) XXX-XXXX"
          className={`form-control form-control-input input-box-with-circle-btn ${Styles["quickscrub-inputbox"]}`}
          onInput={handleInput}
          onChange={(e) => setText(e.target.value)}
        />
        <button
          title="QuisckScrub"
          type="button"
          className={`circle-submit-btn position-absolute me-1 ${Styles["quickscrub-circle-btn"]}`}
          onClick={validateInput}
        >
          <i>
            <svg
              fill="none"
              height="33"
              viewBox="0 0 33 33"
              width="33"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16.7023"
                cy="16.1719"
                r="16"
                className="circle-btn-svg"
              ></circle>
              <path
                clipRule="evenodd"
                d="M22.6551 16.7093L17.1367 7.25781L11.618 16.7093L14.7223 15.8554L15.8636 24.1264H18.6077L19.4942 15.8554L22.6551 16.7093Z"
                fill="white"
                fillRule="evenodd"
              ></path>
            </svg>
          </i>
        </button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default QuickScrub;
