import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios, { AxiosProgressEvent } from "axios";
import Cookies from "js-cookie";
import { useUploadListContext } from "src/contexts/UploadListContext";
import styles from "./ReassignedScrub.module.css";
import ReassignedScrubListItem from "./ReassignedScrubListItem";
import { HttpMethod, callApi } from "src/services/apiService";

export const ReassignedScrub = () => {
  const { campaignId } = useUploadListContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedReassignedProduct, setSelectedReassignedProduct] =
    useState<string>("2");
  const [loginId, setLoginId] = useState(Cookies.get("loginId"));
  const [isLoading, setIsLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    setLoginId(Cookies.get("loginId"));
  }, []);

  useEffect(() => {
    const fetchUploadFileAccess = async () => {
      try {
        const apiUrl = `sessions/ShowUpload?campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        if (response.message === "Yes") {
          setShowComponent(true);
        } else {
          setShowComponent(false);
        }
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    setIsLoading(true);
    if (campaignId !== "") {
      fetchUploadFileAccess();
    }
    setIsLoading(false);
  }, [campaignId]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsLoading(true);
      const file = acceptedFiles[0];
      const acctId = Cookies.get("acctId") ?? "";

      const formData = new FormData();
      formData.append("campaignId", campaignId ?? "");
      formData.append("reassignedProduct", selectedReassignedProduct);
      formData.append("acctId", acctId);
      formData.append("files", file);

      const baseUrl = import.meta.env.VITE_APP_API_URL;
      const apiUrl = `${baseUrl}/Sessions/UploadFile`;
      const headers: { [key: string]: string } = {
        "Content-Type": "multipart/form-data",
      };

      if (loginId) {
        headers["loginId"] = loginId;
      }

      axios
        .post(apiUrl, formData, {
          headers: headers,
          withCredentials: true,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total || progressEvent.loaded; // Safe fallback
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / total,
            );
            setUploadProgress(percentCompleted);
          },
        })
        .then((response) => {
          setUploadProgress(0);
          setIsLoading(false);
        })
        .catch((error) => {
          setUploadProgress(0);
          setIsLoading(false);
        });
    },
    [campaignId, loginId, selectedReassignedProduct],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <>
      {showComponent && (
        <>
          <p className="card-text">
            The input file must be a valid CSV file. The first two columns of
            the CSV file must be PhoneNumber and Date.
          </p>

          <div className="row">
            <div className="col">
              <div className="card" style={{ height: "172px" }}>
                <div className="card-header">Phone Number</div>
                <div className="card-body">
                  <p className="card-text">
                    Must be a 10 digit North America phone number with no
                    leading 1 or +1. Example 509367187.
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-header">Date</div>
                <div className="card-body">
                  <p className="card-text">
                    Must be the date from which Enhanced RND will check if the
                    phone number was reassigned after. It is typically the date
                    the consumer gave the calling party consent to be called.
                    Date can be in the formats of MM/DD/YYYY, MM/DD/YY,
                    YYYY-MM-DD, or YYYYMMDD. For example, 9/28/2021, 9/28/21,
                    2021-09-28, or 20210928.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <span
              className="badge text-bg-warning"
              style={{ fontSize: "16px", marginRight: "5px" }}
            >
              Select Reassigned Product:
            </span>
            <label style={{ marginRight: "5px" }}>
              <input
                className="form-check-input"
                type="radio"
                value="1"
                name="reassignedProduct"
                onChange={() => setSelectedReassignedProduct("1")}
                checked={selectedReassignedProduct === "1"}
              />
              <span className="ps-2">Reassigned Authority</span>
            </label>
            <label style={{ marginRight: "5px" }}>
              <input
                className="form-check-input"
                type="radio"
                value="2"
                checked={selectedReassignedProduct === "2"}
                name="reassignedProduct"
                onChange={() => setSelectedReassignedProduct("2")}
              />
              <span className="ps-2">Reassigned Authority Plus</span>
            </label>
            <label style={{ marginRight: "5px" }}>
              <input
                className="form-check-input"
                type="radio"
                value="3"
                name="reassignedProduct"
                onChange={() => setSelectedReassignedProduct("3")}
                checked={selectedReassignedProduct === "3"}
              />
              <span className="ps-2">Basic RND</span>
            </label>
          </div>

          {isLoading ? (
            <div className="d-flex gap-2 mt-2 mb-3 flex-wrap">
              <div
                {...getRootProps()}
                className={`col ${styles["upload-file-container"]}`}
              >
                <input {...getInputProps()} />
                <i
                  className={`bi bi-hourglass-split ${styles["upload-list-icon"]}`}
                ></i>
                <span className="text-center">UPLOADING...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex gap-2 mt-2 mb-3 flex-wrap">
                <div
                  {...getRootProps()}
                  className={`col ${styles["upload-file-container"]}`}
                >
                  <input {...getInputProps()} />
                  <i
                    className={`bi bi-file-earmark-arrow-up ${styles["upload-list-icon"]}`}
                  ></i>
                  <span className="text-center">UPLOAD REASSIGNED FILE</span>
                </div>
              </div>

              <button
                className="download-file-available mt-3 d-none"
                onClick={() => onDrop([])}
              >
                Upload Data
              </button>
            </>
          )}
        </>
      )}

      {uploadProgress > 0 && (
        <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
          <div
            style={{
              height: "24px",
              backgroundColor: "blue",
              width: `${uploadProgress}%`,
            }}
          >
            {uploadProgress}%
          </div>
        </div>
      )}
      <ReassignedScrubListItem />
    </>
  );
};
export default ReassignedScrub;
