import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";
import KeyValuePair from "src/interfaces/KeyValuePair";
import QuickScrub from "src/pages/Main/UploadList/QuickScrub";
import SVGForPage from "src/components/SVGForPage";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import Styles from "./SubNav.module.css";

const SubNav: React.FC = () => {
  const { quickScrub, acctId } = useAppSelector(selectAuth);
  const { projectId, campaignId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(true);
  //const [showQuickScrub, setShowQuickScrub] = useState(false);
  const [menuItems, setMenuItems] = useState<KeyValuePair[]>([]);

  const fetchSubMenuItems = useCallback(async () => {
    setIsLoading(true);
    try {
      // SubMenu doesn't change based on projectId or campaingId
      // so removing passing it to SubMenu API so removing this:
      // main/SubMenu?projectId=${projectId}&campaignId=${campaignId}
      // When this is present, on account change
      // it will log the user out I believe
      // because as acctId is coming from Redux
      // and Redux state is updated before
      // the context state (ChatGPT confirmed this)
      // - JH 9/11/2024
      const apiUrl = `main/SubMenu`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        setMenuItems(response);
        // const hasQuickScrub = menuItems.some(
        //   (item) => item.key === "quick-scrub",
        // );
        // setShowQuickScrub(hasQuickScrub);
      }
    } catch (error) {
      console.error("Failed to fetch sub menu:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (acctId) {
      fetchSubMenuItems();
    }
  }, [acctId, fetchSubMenuItems]);
  // Note the submenu only changes based on the acctId

  if (isLoading) return <Loading />;
  return (
    <>
      <nav
        className={`flex-column flex-wrap align-items-start ${Styles["nav-container"]}`}
      >
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={`${item.value}/${campaignId}`}
            className={`nav-link ${Styles["right-subnav-link"]} ${window.location.pathname.startsWith(item.value) ? Styles["active-right-subnav-link"] : ""}`}
            title={item.key}
          >
            <SVGForPage pageKeyword={item.value.substring(6)} />
            <span className="d-none d-md-inline">{item.key}</span>
          </Link>
        ))}
        {quickScrub && (
          <div className="d-none d-md-block">
            <QuickScrub />
          </div>
        )}
      </nav>
    </>
  );
};

export default SubNav;
