import React from "react";
import { Grid } from "react-loader-spinner";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles["loading-container"]}>
      <Grid
        height="40"
        width="40"
        color="#0eb0e5"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default Loading;
