import React, { useState, useEffect, useRef } from "react";
import styles from "./BackToTop.module.css";

/**
 * Provides a "Back to Top" button for a scrollable div that has a class `show-scroll-back-to-top`.
   Note you can only have one scrollable div with this class on the page.
 * The button appears when the user scrolls down a specified element more than 200 pixels.
 * It allows users to smoothly scroll back to the top of the scrollable element.
 *
 * @returns {React.ReactElement|null} A button element that is rendered if the button is
 * visible based on the scroll position. Returns `null` if not visible.
 */
const BackToTop: React.FC = () => {
  const SCROLLABLE_DIV_CLASS = ".show-scroll-back-to-top";

  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const isScrollDiv = document.querySelector(
        SCROLLABLE_DIV_CLASS,
      ) as HTMLDivElement;
      if (isScrollDiv) {
        if (isScrollDiv.scrollTop > 200) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    const isScrollDiv = document.querySelector(
      SCROLLABLE_DIV_CLASS,
    ) as HTMLDivElement;

    if (!isScrollDiv) return;

    isScrollDiv.addEventListener("scroll", handleScroll);
    return () => {
      isScrollDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    const scrollDiv = document.querySelector(
      SCROLLABLE_DIV_CLASS,
    ) as HTMLDivElement;
    if (scrollDiv) {
      scrollDiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return isVisible ? (
    <div
      ref={buttonRef}
      onClick={scrollToTop}
      className={styles.backToTop}
      aria-label="Back to top"
    >
      <i className="bi bi-arrow-up"></i> <span>Back to Top</span>
    </div>
  ) : null;
};

export default BackToTop;
