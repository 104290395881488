import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import SaveInProgress from "src/components/SaveInProgress";
import {
  IContactInfo,
  ContactInfoDetails,
} from "src/interfaces/Admin/IContactInfo";
import statesData from "src/data/regionsUSA.json";

const ContactInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState("");
  const [formData, setFormData] = useState<ContactInfoDetails>();
  const [states] = useState(statesData);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<ContactInfoDetails>({ mode: "onChange" });

  const [originalData, setOriginalData] = useState<IContactInfo | null>(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      setIsLoading(true);
      try {
        const apiUrl = "admin/contactinfo";
        const response = await callApi(apiUrl, HttpMethod.GET);
        setFormData(response.contactInfo);
        setOriginalData(response);
        const contactInfoData = response.contactInfo;
        reset(contactInfoData);
      } catch (error) {
        console.error("Failed to fetch contact info:", error);
        setWarningMessage("Failed to fetch contact info.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchContactInfo();
  }, [reset]);

  //Abhi K - Had to separate it out as it was causing a loop
  useEffect(() => {
    if (formData?.email) {
      setConfirmEmail(formData.email);
    }
  }, [formData?.email]);

  const validateEmailFormat = (email: string) => {
    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    if (confirmEmail && !validateEmailFormat(confirmEmail)) {
      setConfirmEmailError("Invalid email format");
    } else {
      setConfirmEmailError("");
    }
  }, [confirmEmail]);

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue("region", event.target.value, { shouldDirty: true });
  };

  const onSubmit = async (formData: ContactInfoDetails) => {
    setSaveInProgress(true);

    try {
      const apiUrl = `admin/contactinfo`;
      const method = HttpMethod.POST;
      const body = formData;

      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
    setSaveInProgress(false);
  };

  const handleReset = () => {
    reset(originalData?.contactInfo);
    setConfirmEmail(originalData?.contactInfo?.email || "");
  };

  const emailValidationRules = {
    required: "This field is required",
    pattern: {
      value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      message: "Invalid email format",
    },
  };

  const optionalEmailValidationRules = {
    pattern: emailValidationRules.pattern, // Reuse the pattern from the required email validation rules
  };

  if (isLoading) {
    return <Loading />;
  }

  if (warningMessage) {
    return <Warning message={warningMessage} />;
  }

  return (
    <>
      <div className="pb-5">
        <h6>My Contact Info</h6>
        {formData && originalData && (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: originalData?.description.long,
              }}
            ></p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid grid-cols-2 gap-4"
            >
              <div className="row">
                <div className="col-3 text-end">First Name:</div>
                <div className="col-3">
                  <input
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                    className="form-control"
                  />
                </div>
                <div className="col-3 text-end">Last Name:</div>
                <div className="col-3">
                  <input
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-3 text-end">Title:</div>
                <div className="col-3">
                  <input {...register("title")} className="form-control" />
                </div>
                <div className="col-3 text-end">Organization Name:</div>
                <div className="col-3">
                  <input
                    {...register("organization", {
                      required: "Organization Name is required",
                    })}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-3 text-end">Address 1</div>
                <div className="col-3">
                  <input
                    {...register("address1", {
                      required: "Address 1 is required",
                    })}
                    className="form-control"
                  />
                </div>
                <div className="col-3 text-end">Phone Number</div>
                <div className="col-3">
                  <input
                    {...register("phoneNumber")}
                    className="form-control"
                  />
                  Ext:
                  <input {...register("phoneExt")} className="form-control" />
                </div>
              </div>

              <div className="row">
                <div className="col-3 text-end">Address 2</div>
                <div className="col-3">
                  <input {...register("address2")} className="form-control" />
                </div>
                <div className="col-3 text-end">Fax Number</div>
                <div className="col-3">
                  <input {...register("faxNumber")} className="form-control" />
                </div>
              </div>

              <div className="row">
                <div className="col-3 text-end">City</div>
                <div className="col-3">
                  <input
                    {...register("city", { required: "City is required" })}
                    className="form-control"
                  />
                </div>
                <div className="col-3 text-end">Email Address</div>
                <div className="col-3">
                  <input
                    {...register("email", emailValidationRules)}
                    className="form-control"
                  />
                  <br />
                  {errors.email && (
                    <p className="text-danger">{errors.email.message}</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-3 text-end">State</div>
                <div className="col-3">
                  <select
                    {...register("region")}
                    defaultValue={formData?.region}
                    className="form-select"
                    onChange={handleRegionChange}
                  >
                    {states.map((state) => (
                      <option key={state.abbrev} value={state.abbrev}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  Zip:{" "}
                  <input {...register("postCode")} className="form-control" />
                </div>
                <div className="col-3 text-end">Confirm Email Address:</div>
                <div className="col-3">
                  <input
                    type="email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    className="form-control"
                    placeholder="Confirm Email"
                  />
                  {confirmEmailError && (
                    <p className="text-danger">{confirmEmailError}</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-3 text-end">Country</div>
                <div className="col-3">
                  <input {...register("country")} className="form-control" />
                </div>
                <div className="col-3 text-end">Web site URL:</div>
                <div className="col-3">
                  <input {...register("url")} className="form-control" />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  Up to four additional E-mail addresses to receive account
                  communications, legal updates, etc:
                </div>

                <div className="col-3 text-end">
                  <input
                    {...register("email2", optionalEmailValidationRules)}
                    className="form-control"
                  />
                </div>
                <div className="col-3">
                  <input
                    {...register("email3", optionalEmailValidationRules)}
                    className="form-control"
                  />
                </div>
                <div className="col-3 text-end">
                  <input
                    {...register("email4", optionalEmailValidationRules)}
                    className="form-control"
                  />
                </div>
                <div className="col-3">
                  <input
                    {...register("email5", optionalEmailValidationRules)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  E-mail address to receive SAN expiration warning (if not
                  provided then will be sent to the primary contact above):
                </div>
                <div className="col-3">
                  <input
                    {...register("emailSanExpiration")}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-span-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  Save Contact Info
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <SaveInProgress isVisible={saveInProgress} />
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default ContactInfo;
