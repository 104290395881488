import React, { useState } from "react";
import { IEbrRecord, EbrTypeLabels } from "src/interfaces/Main/EBRMaster";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { CpaSection } from "src/interfaces/Main/EBRMaster";

interface SearchEBRDatabaseProps {
  fetchUndoDetails: () => void;
  access: CpaSection | undefined;
}

const SearchEBRDatabase: React.FC<SearchEBRDatabaseProps> = ({
  access,
  fetchUndoDetails,
}) => {
  const { projectId, campaignId } = useUploadListContext();
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<IEbrRecord[]>([]);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [checkedSearchResults, setCheckedSearchResults] = useState<number[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const searchCriteria = searchValue.trim();
      const apiUrl = `main/ebrmaster/search?projId=${projectId}&campaignId=${campaignId}&search=${searchCriteria}`;
      const response = await callApi(apiUrl, HttpMethod.POST, searchCriteria);
      setIsLoading(false);

      setSearchResults(response);
      setShowSearchResults(true);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handleCheckAll = () => {
    setCheckedSearchResults(searchResults.map((result) => result.ebrId ?? 0));
  };

  const handleUncheckAll = () => {
    setCheckedSearchResults([]);
  };

  const handleSearchResultsChecked = (ebrId: number, isChecked: boolean) => {
    if (isChecked) {
      setCheckedSearchResults((prev) => [...prev, ebrId]);
    } else {
      setCheckedSearchResults((prev) => prev.filter((id) => id !== ebrId));
    }
  };

  const handleRemoveChecked = async () => {
    try {
      setIsLoading(true);
      const apiUrl = `main/ebrmaster/remove?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(
        apiUrl,
        HttpMethod.POST,
        checkedSearchResults,
      );
      setSearchResults((prev) =>
        prev.filter(
          (result) => !checkedSearchResults.includes(result.ebrId ?? 0),
        ),
      );
      if (searchResults.length === 0) {
        setShowSearchResults(false);
      }
      setCheckedSearchResults([]);
      console.log("Search results:", response);
      fetchUndoDetails();
      setIsLoading(false);
    } catch (error) {
      console.error("Error removing items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    if (!dateString) return "";
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  if (isLoading || access === undefined) {
    return <Loading />;
  }

  return (
    <div className="card mt-3 mb-3">
      <div className="card-header">
        <label htmlFor="search-ebr-master">Search Project's EBRMaster:</label>{" "}
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Phone #"
          className="form-control d-inline-block"
          style={{ width: "200px" }}
          id="search-ebr-master"
        />
        <button
          type="button"
          className="btn btn-primary ms-2"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>

      {showSearchResults && (
        <>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  {access.Delete && <th>&nbsp;</th>}
                  <th>Phone #</th>
                  <th>Date of Last Contact</th>
                  <th>Type</th>
                  <th>Reference # (Opt.) </th>
                  <th>Brand / Division / Product Line (Opt.)</th>
                  <th>E-mail Address (Opt.)</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, index) => (
                  <tr key={index}>
                    {access.Delete && (
                      <td>
                        <input
                          type="checkbox"
                          className="me-2"
                          checked={checkedSearchResults.includes(
                            result.ebrId ?? index,
                          )}
                          onChange={(e) =>
                            handleSearchResultsChecked(
                              result.ebrId ?? index,
                              e.target.checked,
                            )
                          }
                        />
                      </td>
                    )}
                    <td>{`${result.fullPhone}`}</td>
                    <td className="text-center">
                      {formatDate(result.dateOfLastContact) || ""}
                    </td>
                    <td>{EbrTypeLabels[result.type] || ""}</td>
                    <td>{result.referenceNum || ""}</td>
                    <td>{result.brand || ""}</td>
                    <td>{result.email || ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {access.Delete && (
            <div className="m-3">
              <button
                onClick={handleCheckAll}
                className="btn btn-secondary me-3"
              >
                Check All
              </button>
              <button
                onClick={handleUncheckAll}
                className="btn btn-secondary me-3"
              >
                Uncheck All
              </button>
              <button onClick={handleRemoveChecked} className="btn btn-danger">
                Remove Checked from DB
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchEBRDatabase;
