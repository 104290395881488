import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IEbrRecord, EbrType } from "src/interfaces/Main/EBRMaster";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { CpaSection } from "src/interfaces/Main/EBRMaster";
import EbrRecordRow from "./EbrRecordRow";
import SaveInProgress from "src/components/SaveInProgress";

interface IFormInput {
  records: IEbrRecord[];
}

interface AddEBRRecordProps {
  fetchUndoDetails: () => void;
  access: CpaSection | undefined;
}

const AddEBRRecord: React.FC<AddEBRRecordProps> = ({
  access,
  fetchUndoDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { projectId, campaignId } = useUploadListContext();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const { register, getValues, reset, control, handleSubmit } =
    useForm<IFormInput>({
      defaultValues: {
        records: Array(5).fill({
          fullPhone: "",
          dateOfLastContact: "",
          type: EbrType.Sale,
          reference: "",
          brand: "",
          email: "",
        }),
      },
    });

  const handleAdd = async () => {
    setSaveInProgress(true);
    const formData = getValues();
    const validRecords = formData.records.filter((record) => {
      const isPhoneNumberValid =
        record.fullPhone && record.fullPhone.replace(/\D/g, "").length === 10;
      const isDateOfLastContactValid = !!record.dateOfLastContact;
      const isTypeValid = !!record.type;
      return isPhoneNumberValid && isDateOfLastContactValid && isTypeValid;
    });

    if (validRecords.length === 0) {
      console.error("No valid records to add/update.");
      return;
    }

    // Fix the date format before sending to the API
    const formattedRecords = validRecords.map((record) => ({
      ...record,
      dateOfLastContact: new Date(record.dateOfLastContact).toISOString(),
    }));

    try {
      if (!isLoading) {
        setIsLoading(true);
      }

      const apiUrl = `main/ebrmaster/add?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.POST, formattedRecords);

      if (response) {
        fetchUndoDetails();
        reset();
      } else {
        throw new Error("An unknown response received from the server.");
      }
    } catch (error) {
      console.error("Error adding data:", error);
    } finally {
      if (isLoading) {
        setIsLoading(false);
      }
      setSaveInProgress(false);
    }
  };

  return (
    <div className="mt-3 card col-12">
      <div className="card-header">
        Add Existing Business Relationship (EBR) and Express Consent
      </div>
      <div className="card-body">
        Pre-existing business relationship and express consent allows a
        telemarketer to call phone numbers in the DNC databases but definitions
        vary, so we ask for details to automatically determine validity per
        state.{" "}
        <b>
          Note that your last contact date may be in the future and is the
          expected date of last payment or shipment.
        </b>
        <br />
        For Non-profits calling into Alaska & South Dakota: Enter all members as
        Permission, donors as Purchases, and those who expressed interest in
        donation as Inquiries
        <div className="mt-3"></div>
        <form onSubmit={handleSubmit(handleAdd)}>
          <table className="table w-100">
            <thead>
              <tr>
                <th>Phone #</th>
                <th>Date of Last Contact</th>
                <th>Type</th>
                <th>Reference # (Opt.)</th>
                <th>Brand / Division / Product Line (Opt.)</th>
                <th>E-mail Address (Opt.)</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 5 }).map((_, index) => (
                <EbrRecordRow
                  key={index}
                  register={register}
                  control={control}
                  index={index}
                />
              ))}
            </tbody>
          </table>
          <button
            type="button"
            onClick={handleAdd}
            className="btn btn-primary mt-2"
          >
            + Add / Update
          </button>
          <SaveInProgress isVisible={saveInProgress} />
        </form>
      </div>
    </div>
  );
};

export default AddEBRRecord;
