import React, { useState, useEffect } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useUploadListContext } from "src/contexts/UploadListContext";
import SafeHtml from "src/components/SafeHtml";
import Styles from "src/components/ScrubWarnings.module.css";

const ScrubWarnings = () => {
  const { projectId, campaignId } = useUploadListContext();
  const [scrubWarnings, setScrubWarnings] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchWarnings = async () => {
      try {
        const apiUrl = `main/ScrubOptions/GetScrubWarnings?projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        setScrubWarnings(response);
      } catch (error) {
        console.error("Failed to fetch scrub warnings:", error);
        setError("Failed to load warnings");
      } finally {
        setLoading(false);
      }
    };

    if (campaignId) {
      fetchWarnings();
    }
  }, [campaignId, projectId]);

  if (!campaignId) {
    return null;
  }

  if (loading) return <Loading />;

  return (
    <div>
      <h6 className="d-none">
        Warnings for Project: {projectId} Campaign: {campaignId}
        {error}
      </h6>
      {scrubWarnings.length > 0 ? (
        <>
          {scrubWarnings.map((setting, index) => (
            <div
              className={`card mt-3 mb-3 position-static ${Styles["scrub-warning-box"]}`}
              key={index}
            >
              <div className="card-body d-flex align-items-center">
                <i
                  className={`h1 bi bi-exclamation-triangle-fill pe-3 ${Styles["scrub-warning-icon"]}`}
                ></i>
                <SafeHtml html={setting} />
              </div>
            </div>
          ))}
        </>
      ) : (
        <p> </p>
      )}
    </div>
  );
};

export default ScrubWarnings;
