import React, { useState, useEffect, useCallback } from "react";
import { callApi, HttpMethod } from "src/services/apiService";
import Regions from "./Regions";
import Loading from "src/components/Loading";
import State from "src/components/ComplianceGuide/State";
import { useLayoutContext } from "src/contexts/LayoutContext";
import SafeHtml from "src/components/SafeHtml";
import styles from "./Guide.module.css";

const Guide = () => {
  const [topics, setTopics] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTopics, setSelectedTopics] = useState<
    { topicId: number; topicName: string; topicNote: string }[]
  >([]);
  const [selectedRegions, setSelectedRegions] = useState<
    { state: string; stateName: string }[]
  >([]);

  useEffect(() => {
    async function ShowTopics() {
      try {
        let apiUrl = `ComplianceGuide/Topics`;
        const fetchedData = await callApi(apiUrl, HttpMethod.GET);
        setTopics(fetchedData);
      } catch (err) {
        const errorMessage = (err as Error).message;
        console.log(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }

    ShowTopics();
  }, []);
  const { setHeading, setTagline } = useLayoutContext();

  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Regulatory Guide");
      setTagline("View Regulatory Rules by Topics and States");
    }
  }, [setHeading, setTagline]);

  // Logs the count of fetched states. Is passed as a prop to <State /> component.
  // useCallback has to be used to stablize the function reference or the State
  // components useEffect will run in loop forever.
  const onStatesFetchedCount = useCallback((count: number) => {
    console.log("Fetched count:", count);
  }, []);

  const handleTopicCheckboxChange = (
    topicId: number,
    topicName: string,
    topicNote: string,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      setSelectedTopics([...selectedTopics, { topicId, topicName, topicNote }]);
    } else {
      setSelectedTopics(selectedTopics.filter((s) => s.topicId !== topicId));
    }
  };

  const topicNotes = selectedTopics
    .filter((topic) => topic.topicNote && topic.topicNote.trim() !== "")
    .map((topic) => `<b>${topic.topicName}:</b> ${topic.topicNote}`)
    .join("<br />");

  const renderContent = () => {
    if (selectedRegions.length === 0 && selectedTopics.length === 0) {
      return "Please select a topic and state to continue.";
    } else if (selectedRegions.length === 0) {
      return "Please select a state to continue.";
    } else if (selectedTopics.length === 0) {
      return "Please select a topic to continue.";
    } else {
      return (
        <>
          {topicNotes && (
            <div className="alert alert-primary">
              <SafeHtml html={topicNotes} />
            </div>
          )}
          <div className="p-3">
            <Regions topics={selectedTopics} regions={selectedRegions} />
          </div>
        </>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {((selectedTopics.length === topics.length &&
            selectedRegions.length > 8) ||
            (selectedRegions.length > 6 && selectedTopics.length >= 2)) && (
            <div className="alert-message" style={{ color: "red" }}>
              <b>Note: this selection may take some time to load.</b>
            </div>
          )}
          {
            <div className="w-100">
              <div className="compliance-intro col">
                <div className="compliance-card-container">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="compliance-card h-100 w-100">
                        <div className="card-block">
                          <h4 className="topic-options">
                            View by Topic or Rule
                          </h4>
                          {/* Prohibitting check all as it hangs browser <button onClick={handleCheckAllTopics}>Check All</button>&nbsp;
                          <button onClick={handleUncheckAllTopics}>Check None</button><br /> */}

                          <div className={styles["option-select-boxes"]}>
                            {topics.map((topic) => (
                              <div
                                key={topic.id}
                                className="topic-checkbox px-1"
                              >
                                <input
                                  id={topic.id.toString()}
                                  className="form-check-input"
                                  type="checkbox"
                                  value={topic.id}
                                  checked={selectedTopics.some(
                                    (t) => t.topicId === topic.id,
                                  )}
                                  onChange={(e) =>
                                    handleTopicCheckboxChange(
                                      topic.id,
                                      topic.topic,
                                      topic.notes,
                                      e.target.checked,
                                    )
                                  }
                                />
                                &nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor={topic.id}
                                >
                                  {topic.topic}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 states-tablet">
                      <div className="compliance-card h-100 w-100">
                        <div className="card-block">
                          <h4 className="topic-options">
                            View by State or Region
                          </h4>
                          <div
                            className={
                              styles["option-select-box-style-second-div"]
                            }
                          >
                            <State
                              onStatesChange={setSelectedRegions}
                              selectedStates={selectedRegions}
                              onStatesFetchedCount={onStatesFetchedCount}
                              initialFetchBehavior="all"
                              styleStateWrapper={styles["option-select-boxes"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <div className="regulatory-guide-section">
                    {renderContent()}
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

export default Guide;
