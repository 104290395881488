import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUploadListContext } from "src/contexts/UploadListContext";
import ICpaSection from "src/interfaces/ICpaSection";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useForm } from "react-hook-form";
import SaveInProgress from "src/components/SaveInProgress";
import UserRoleIcon from "src/components/UserRoleIcon";

type CampaignDataType = {
  Name: string;
  Notes: string;
  Status: number;
  Default: boolean;
  CreatedOn: string;
  CreatedBy: string;
  CreatedByUserRole: number;
  CreatedByUserStatus: number;
  DeactivatedOn: string;
  DeactivatedBy: string;
  DeactivatedByUserRole: number;
  DeactivatedByUserStatus: number;
};

function mapApiResponseToCampaignData(apiResponse: any): CampaignDataType {
  return {
    Name: apiResponse.name,
    Notes: apiResponse.notes,
    Status: apiResponse.status,
    Default: apiResponse.default,
    CreatedOn: apiResponse.createdOn,
    CreatedBy: apiResponse.createdBy,
    CreatedByUserRole: apiResponse.createdByUserRole,
    CreatedByUserStatus: apiResponse.createdByUserStatus,
    DeactivatedOn: apiResponse.deactivatedOn,
    DeactivatedBy: apiResponse.deactivatedBy,
    DeactivatedByUserRole: apiResponse.deactivatedByUserRole,
    DeactivatedByUserStatus: apiResponse.deactivatedByUserStatus,
  };
}

const CampaignSettings = () => {
  const navigate = useNavigate();
  const [access, setAccess] = useState<ICpaSection>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const {
    campaignId,
    campaignName,
    setRefreshTree,
    setCampaignName,
    setSourceComponent,
  } = useUploadListContext();
  const [campaignData, setCampaignData] = useState<CampaignDataType | null>(
    null,
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CampaignDataType>();

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        let apiUrl = `main/Campaigns/GetCampaign?campaignId=${campaignId}`;
        const apiResponse = await callApi(apiUrl, HttpMethod.GET);
        const data = mapApiResponseToCampaignData(apiResponse.data);
        if (apiResponse) {
          setCampaignData(data);
          setAccess(apiResponse.cpaSection);
          setValue("Notes", data.Notes);
          setValue("Name", data.Name);
        }
      } catch (error) {
        console.error("Failed to fetch campaign data:", error);
      }
    };

    if (campaignId !== "") fetchCampaignData();
  }, [campaignId, setValue]);

  useEffect(() => {
    //first check for page access and redirect if not allowed
    if (access && !access.update) {
      window.location.href = "/main/upload-list";
    }
  }, [access]);

  const onSubmit = async (data: CampaignDataType) => {
    setSaveInProgress(true);
    try {
      const apiUrl = "main/Campaigns/UpdateCampaignSettings";
      const payload = {
        CampaignId: parseInt(campaignId),
        ...data,
      };
      const response = await callApi(apiUrl, HttpMethod.POST, payload);
      console.log("Save successful:", response);
      if (data.Name !== campaignName) {
        setCampaignName(data.Name);
        setRefreshTree(true);
      }
    } catch (error) {
      console.error("Failed to save campaign settings:", error);
    } finally {
      setSaveInProgress(false);
    }
  };

  const handleDeactivateClick = async () => {
    try {
      let apiUrl = `main/Campaigns/ToggleActiveCampaign?campaignId=${campaignId}`;
      const apiResponse = await callApi(apiUrl, HttpMethod.GET);

      if (apiResponse) {
        const updatedData = mapApiResponseToCampaignData(apiResponse);
        setCampaignData(updatedData);
      }

      console.log("Deactivate clicked. Campaign ID:", campaignId);
    } catch (error) {
      console.error("Failed to deactivate campaign:", error);
    }
  };

  const navigateToScrubOptions = () => {
    setSourceComponent("Campaign");
    navigate(`/main/scrub-options/${campaignId}`);
  };

  if (!access) return <Loading />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6>Campaign Settings</h6>
        <small>
          These settings are for the current campaign. (Note that e-mail
          submissions are always processed under the Default Campaign.)
        </small>
        <hr />
        {campaignData && (
          <>
            {campaignData.Default === false && (
              <>
                {campaignData.Status === 1 && access.deactivate === true && (
                  <>
                    <p>
                      You can deactivate a campaign to hide it from the tree and
                      other lists and to prevent it from being retroactively
                      scrubbed.
                    </p>
                    <button
                      className="btn btn-danger mt-2"
                      onClick={handleDeactivateClick}
                    >
                      Deactivate
                    </button>
                  </>
                )}

                {campaignData.Status === 2 && access.reactivate === true && (
                  <>
                    <p>This Campaign is deactivated.</p>
                    <button
                      className="btn btn-secondary mt-2"
                      onClick={handleDeactivateClick}
                    >
                      Reactivate
                    </button>
                  </>
                )}
              </>
            )}
            {campaignData.Default === false && (
              <div>
                <b>Rename Campaign: </b>
                <input
                  type="text"
                  className="form-control"
                  {...register("Name", {
                    required: "Campaign name cannot be blank",
                  })}
                />
                {errors.Name && (
                  <p className="text-danger">{errors.Name.message}</p>
                )}
              </div>
            )}
            <div className="mt-3">
              <b>Campaign Code:</b> {campaignId}
              <hr />
            </div>
            {campaignData?.CreatedBy !== "" && (
              <div className="d-flex align-items-start">
                <div className="col d-flex align-items-center justify-content-start">
                  <span className="fw-bold">Created By:</span>
                  <span className="ms-2 d-flex align-items-center">
                    <UserRoleIcon
                      Role={campaignData?.CreatedByUserRole}
                      Status={campaignData?.CreatedByUserStatus}
                    />
                    <span className="ms-1">
                      {campaignData?.CreatedBy} <b>on</b>{" "}
                      {campaignData?.CreatedOn}{" "}
                    </span>
                  </span>
                </div>
              </div>
            )}

            {campaignData.Status === 2 && (
              <div className="d-flex align-items-start">
                <div className="col d-flex align-items-center justify-content-start">
                  <span className="fw-bold">Deactivated By:</span>
                  <span className="ms-2 d-flex align-items-center">
                    <UserRoleIcon
                      Role={campaignData?.DeactivatedByUserRole}
                      Status={campaignData?.DeactivatedByUserStatus}
                    />
                    <span className="ms-1">
                      {campaignData?.DeactivatedBy} <b>on</b>{" "}
                      {campaignData?.DeactivatedOn}
                    </span>
                  </span>
                </div>
              </div>
            )}
            <div className="mt-3">
              <b>About this Campaign / Notes:</b>
              <textarea className="form-control" {...register("Notes")} />
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={navigateToScrubOptions}
              >
                {campaignData.Default === true
                  ? "Project Details"
                  : "Campaign Details"}
                /Default Scrub Options...
              </button>
            </div>
          </>
        )}
        <SaveInProgress isVisible={saveInProgress} />
      </form>
    </>
  );
};

export default CampaignSettings;
