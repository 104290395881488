import React, { useEffect } from "react";
import { ComplianceGuideProps } from "src/interfaces/ComplianceGuide/ComplianceGuideProps";
import Citation from "src/components/ComplianceGuide/Citation";
import SafeHtml from "src/components/SafeHtml";
import RegulatoryGuideSectionHeader from "src/components/ComplianceGuide/RegulatoryGuideSectionHeader";
import RegulatoryGuideSection from "src/components/ComplianceGuide/RegulatoryGuideSection";

const NPOSolicitationExemptionsChart: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className="regulatory-contents">
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }
  const records = JSON.parse(data);

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${records[0].state})`} />
      <div className="regulatory-contents">
        <div className="margin22">
          <table className="table table-border content-width-table mt-3">
            <tbody>
              <tr>
                <th colSpan={3}>
                  Is NPO campaign EXEMPT from using the DNC list?
                </th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <div className="margin31">By Employee or Volunteer</div>
                </th>
                <th>
                  <div className="margin31">
                    Outsourced For-Profit Call Center
                  </div>
                </th>
              </tr>
              <tr>
                <td>Calls with solicitation for donation</td>
                <td align="center">
                  <div className="margin31">
                    {records[0].npoSelfDonations === 1 ? "Yes" : "No"}
                  </div>
                </td>
                <td align="center">
                  <div className="margin31">
                    {records[0].npoOutDonations === 1 ? "Yes" : "No"}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Calls with solicitation for a sale of good/service</td>
                <td align="center">
                  <div className="margin31">
                    {records[0].npoSelfSales === 1 ? "Yes" : "No"}
                  </div>
                </td>
                <td align="center">
                  <div className="margin31">
                    {records[0].npoOutSales === 1 ? "Yes" : "No"}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {records.map((record: any, index: number) =>
          record.excerpt !== null ? (
            <div key={`record-section-${index}`}>
              <RegulatoryGuideSection
                label={`State Commercial Registration Exemption for ${
                  record.exemptionCategory === "Any"
                    ? "any nonprofit organization"
                    : record.exemptionCategory
                }`}
                value={<SafeHtml html={record.excerpt} />}
              />

              <RegulatoryGuideSection
                label="Citation"
                value={
                  <Citation
                    statuteId={record.statuteId}
                    statuteName={record.statuteNumber}
                    ref1={record.ref1}
                    ref2={record.ref2}
                    ref3={record.ref3}
                  />
                }
              />
            </div>
          ) : null,
        )}
      </div>
    </>
  );
};

export default NPOSolicitationExemptionsChart;
