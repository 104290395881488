import React from "react";
import Card from "./Card";
import { SectionData } from "src/interfaces/ComplianceGuide/OtherTools/SectionData";
import GroupIcon from "./GroupIcon";

const Section: React.FC<SectionData> = ({ group, cards }) => (
  <div className="pb-5">
    <h3>
      <GroupIcon group={group} />
      {group}
    </h3>
    <div className="d-flex flex-wrap gap-4">
      {cards.map((card, index) => (
        <React.Fragment key={index}>
          <Card {...card} />
        </React.Fragment>
      ))}
    </div>
  </div>
);

export default Section;
